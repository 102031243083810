import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ResponsiveAppBar from "../Dashboard/components/appbar"
import { Button, Drawer } from '@mui/material';
import { useEffect, useState } from 'react';
import AddProjectPowerBIProtfolio from './Components/addPowerBI';
import { getLatestPowerBIResult } from '../../api/powerBIAPIS/powerBiApis';

export function ProjectProtfolio () {

    const [anchor, setAnchor] = useState(false);    
    const [projectData, setProjectData] = useState({
        development:0, 
        support:0, 
        retainer:0, 
        fixedScope:0, 
        allocated:0, 
        benched:0, 
        inProgress:0, 
        onHold:0
    });
    
    const handleAddValue = () => {
        setAnchor(true);
    }

    const toggleDrawer = () => {
        setAnchor(false);        
        return false
    };
    
    const getPowerBIItems = async () => {
        const res = await getLatestPowerBIResult();        
        setProjectData(res);
    }
    useEffect(() => {
        getPowerBIItems();
    }, []);

    return (
        <>
        <div className='mainApp'>
            <ResponsiveAppBar /> 

            <Grid container spacing={0} style={{marginBottom: '10px'}}>
                <Grid item md={10} className="dashboard-header">
                    <div className="titleBar">Project Protfolio</div>            
                </Grid>                   
                <Grid item md={2}>            
                    <Button 
                        onClick={handleAddValue}
                        className='add-resource'
                    >
                        Update Value
                    </Button>
                    <Drawer
                        anchor={'right'}
                        open={anchor}
                        onClose={toggleDrawer}
                    >                                    
                        <AddProjectPowerBIProtfolio toggleDrawer={toggleDrawer} projectData={projectData} setProjectData={setProjectData}/>
                    </Drawer>
                </Grid>                    
                
            </Grid>
            
            <Box>
                <Grid container spacing={0}>
                    <Grid item md={3} className="box">
                        <div className="box-content">
                            <div className='box-title'>
                                Projects
                            </div>

                            <Grid container spacing={2}>
                                <Grid item md={6}>
                                    <div className='box-sub-titlle'>
                                        Development
                                    </div>
                                    <div className='box-sub-content'>
                                        {projectData.development}
                                    </div>                                    
                                </Grid>
                                <Grid item md={6}>
                                    <div className='box-sub-titlle'>
                                        Support
                                    </div>
                                    <div className='box-sub-content'>
                                        {projectData.support}
                                    </div>  
                                </Grid>                                    
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item md={3} className="box">
                        <div className="box-content">
                            <div className='box-title'>
                                Project Type
                            </div>
                            <Grid container spacing={2}>
                                <Grid item md={6}>
                                    <div className='box-sub-titlle'>
                                    Retainer
                                    </div>
                                    <div className='box-sub-content'>
                                        {projectData.retainer}
                                    </div>                                    
                                </Grid>
                                <Grid item md={6}>
                                    <div className='box-sub-titlle'>
                                    Fixed Scope
                                    </div>
                                    <div className='box-sub-content'>
                                        {projectData.fixedScope}
                                    </div>  
                                </Grid>                                    
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item md={3} className="box">
                        <div className="box-content">
                            <div className='box-title'>
                                Resources
                            </div>
                            <Grid container spacing={2}>
                                <Grid item md={6}>
                                    <div className='box-sub-titlle'>
                                    Allocated
                                    </div>
                                    <div className='box-sub-content'>
                                        {projectData.allocated}
                                    </div>                                    
                                </Grid>
                                <Grid item md={6}>
                                    <div className='box-sub-titlle'>
                                    Benched
                                    </div>
                                    <div className='box-sub-content'>
                                        {projectData.benched}
                                    </div>  
                                </Grid>                                    
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item md={3} className="box">
                        <div className="box-content">
                            <div className='box-title'>
                                Discoveries
                            </div>
                            <Grid container spacing={2}>
                                <Grid item md={6}>
                                    <div className='box-sub-titlle'>
                                    In Progress
                                    </div>
                                    <div className='box-sub-content'>
                                        {projectData.inProgress}
                                    </div>                                    
                                </Grid>
                                <Grid item md={6}>
                                    <div className='box-sub-titlle'>
                                    On hold
                                    </div>
                                    <div className='box-sub-content'>
                                        {projectData.onHold}
                                    </div>  
                                </Grid>                                    
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        
            <iframe title="JIRA with Prod_MySQL" width="100%" height="790" 
                src="https://app.powerbi.com/view?r=eyJrIjoiODY2NDI5MjAtNjNhZi00Nzg1LThmNzUtY2Y3NGI5ZTc3MTJiIiwidCI6ImE1MjNlYzQwLTVlYmYtNGRmZC1iNjQwLTQxYmI4YjQ5Nzg1NiIsImMiOjl9"  allowFullScreen>
            </iframe>
        </div>

        </>
    )
}