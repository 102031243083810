export function getDateFromString(dateTime: any){
        if (dateTime){
            return new Date(dateTime).toDateString();
        }
        else{
            return "";
        }
      };

export function getBoolenYesOrNO(val: any){    
    
    if (val === true){
        return "Yes";
    }
    else{
        return "No";
    }
}

export function getBoolenActiveOrNOTActive(val: any){    
    
    if (val === true){
        return "Active";
    }
    else{
        return "Not Active";
    }
}

export function getOnlyDatefromDateString(dateTime: any){    
    if (dateTime){
        const date = dateTime.split("T")
        return date[0]
    }
    else{
        return '';
    }
}

export function getBudgetedHoursProject(start_date: any, end_date: any){
    if (start_date !== undefined && end_date !== undefined){

        const startDate = new Date(start_date);
        const endDate = new Date(end_date);        
    
        const diffTime  = endDate?.getTime() - startDate?.getTime()
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); 

        let count = 0;
        let currentDate = new Date(startDate);

        while (currentDate < endDate) {
            const dayOfWeek = currentDate.getDay();
            // Check if the day is a weekday (Monday to Friday)
            if (dayOfWeek !== 0 && dayOfWeek !== 6) { // 0 = Sunday, 6 = Saturday
                count += 1;
            }
            // Move to the next day
            currentDate.setDate(currentDate.getDate() + 1);
        }

        
        // // Calculate number of weekends during the whole duration
        // var weekdends = Math.floor(diffDays/7) * 2 ;

        // console.log(weekdends, "weekends");

        // // Adjust weekends depend on start days 
        // (diffDays%7)+startDate.getDay() == 6 ? weekdends+=2 : 
        //     (diffDays%7)+startDate.getDay() == 5 ? weekdends+=1 :
        //     weekdends;
        // if ()
        // (diffDays%7)+startDate.getDay() == 6 ? weekdends+=2 : 
        // (diffDays%7)+startDate.getDay() == 5 ? weekdends+=1 :
        // weekdends;

        // Calculate Working Days 
        // var workDays = diffDays - weekdends;
        // console.log(workDays, "eeeks", diffDays);
        
        

        const budgetedHours = 8 * count;

        return budgetedHours
    }
    else{
        return "Calculating ..."
    }
}

export function counBillableResourcesOfProjects(resources: any){

    var count = 0;
    if (resources){
        for (let i =0; i < resources.length; i ++){
            if(resources[0]["Events"]["billable"] === true){
                count += 1;
            }            
        }
    }
    return count
}

export function countNonBillableResourcesOfProjects(resources: any){

    var count = 0;
    if (resources){
        for (let i =0; i < resources.length; i ++){
            if(resources[0]["Events"]["billable"] === false){
                count += 1;
            }            
        }
    }
    return count
}

export function counBillableProjectsOfResources(projects: any){

    var count = 0;
    if (projects){
        for (let i =0; i < projects.length; i ++){
            
            if(projects[i]["Events"]["billable"] === true){
                count += 1;
            }            
        }
        console.log(projects);
        
    }
    return count
}

export function counNonBillableProjectsOfResources(projects: any){

    var count = 0;
    if (projects){
        for (let i =0; i < projects.length; i ++){
            
            if(projects[i]["Events"]["billable"] === false){
                count += 1;
            }            
        }
        console.log(projects);
        
    }
    return count
}
export function getFullName(resource: any){
    if (resource){
        return resource?.firstName + " " + resource?.lastName
    }
    else{
        return ""
    }
}

export function fetchTotalAllocatedHours(projects: any){
    if (projects){
        var total_allo_hr = 0;
        for (let i = 0; i < projects.length; i++){
            console.log(projects[i]?.Events?.allocatedHours);
            
            total_allo_hr += projects[i]?.Events?.allocatedHours
        }
        return total_allo_hr + " hrs";
    }
    else{
        return "Calculating .."
    }
}