import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import  ResponsiveAppBar  from '../Dashboard/components/appbar';

import ProjectCardDetail from './Components/projectCard';
import { Box, Button, Drawer } from '@mui/material';
import { Theme } from '@mui/material/styles';
import ProjectResourceTable from './Components/projectResourceTable';
import { toast } from 'react-toastify';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from 'react-router-dom';
import UpdateProjectAnchorDrawer from './Components/updateProject';
import { fetchAllManagers } from '../../api/resourcesAPIs/resourceApis';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

  
function ProjectsDetail() {

    const [loader, setloader] = useState(false);
    const [projectData, setProjectData] = React.useState<any>({});
    const [anchor, setAnchor] = useState(false);
    const [updateProject, setUpdateProject] = useState({});
    const [managersList, setManagersList] = React.useState([{
        id: "",
        firstName: "",
        lastName: ""
      }]); 
    const location = useLocation();
    const navigate = useNavigate();

    const fetchProject = async (projectId:any) => {
        const api_url = process.env.REACT_APP_API_URL;
        const url = api_url + `projects/${projectId}`
        const token = localStorage.getItem('token');
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "token " + token);

        const requestOptions = {
        method: "GET",
        headers: myHeaders,
        };

        setloader(true);
        await fetch(url, requestOptions)
        .then((response) => response.json())
        .then((result) => {
            console.log(result);
            setProjectData(result)
            setloader(false)
        })
        .catch((error) => {
            toast.error(error)
            setloader(false)
        });      
    }

    useEffect( () => {
        const searchParams = new URLSearchParams(location.search);
        const projectId = searchParams.get('projectId');
        if (projectId) {
            fetchProject(projectId);
        }
        fetchManagers();
    }, [])

    const handleStateAnchor = (data:any) => {
        setUpdateProject(data)
        setAnchor(true)
        return true;
      };

    const toggleDrawer = () => {
        setAnchor(false);
        updateProjectList()
        return false
      };

    const handleBackButton = () => {
        navigate("/projects")
      }

    const fetchManagers = async () => {
        const result = await fetchAllManagers();
        if (result){
          setManagersList(result)
        }
      }

    const updateProjectList = () => {
        const searchParams = new URLSearchParams(location.search);
        const projectId = searchParams.get('projectId');
        if (projectId) {
            fetchProject(projectId);
        }
    }
      
  return (
    <Grid spacing={0} className='mainApp'>
            <ResponsiveAppBar />             

            <Box className='dash-container'>
                <Grid>
                    <Grid item md={1}></Grid>
                    <Grid item md={10}>
                        <Grid container >
                            <Grid item md={7}>

                                <div className='titleBar' style={{display:'flex', alignItems:'center'}}>
                                    
                                    <div onClick={handleBackButton} style={{cursor:'pointer'}}>
                                        <ArrowBackIcon />
                                    </div>

                                    {projectData ? <div className='titleBar' style={{marginLeft: "20px"}}>
                                    {projectData?.projectName}    
                                </div> : 'Name' }                       
                                </div>
                            </Grid>
                            <Grid item md={2}></Grid>                            
                            <Grid item md={3}>
                                <Button className='rsc-edit-btn' onClick={() =>  handleStateAnchor(projectData)}>
                                    Edit Project
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
            </Box>

            <Box className='card-container'>
                <Grid>
                    <Grid item md={1}></Grid>
                    <Grid item md={10}>
                        <ProjectCardDetail project={projectData} />
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>                    
            </Box>

            <Box className="tables-section">
                
                <Grid>
                    <Grid item md={1}></Grid>
                    <Grid item md={10}>
                        <ProjectResourceTable rowsData={projectData}/>  
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
                        
            </Box>

            <Drawer
            anchor={'right'}
            open={anchor}
            onClose={toggleDrawer}
            >                                    
                <UpdateProjectAnchorDrawer toggleDrawer={toggleDrawer} updateProjectData={updateProject} manager_list={managersList}/>
            </Drawer>

    </Grid>

  );
}

export default ProjectsDetail;
