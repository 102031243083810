const api_url = process.env.REACT_APP_API_URL;            

const token = localStorage.getItem('token');
const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "token " + token);

const getrequestOptions = {
    method: "GET",
    headers: myHeaders
};

interface PowerBI {
    development: number;
    support: number;
    allocated: number;
    benched: number;
    retainer: number;
    fixedScope: number;
    inProgress: number;
    onHold: number;
}

export const getLatestPowerBIResult = async () : Promise<PowerBI> => {
    let res = {
        development: 0,
        support: 0,
        allocated: 0,
        benched: 0,
        retainer: 0,
        fixedScope: 0,
        inProgress: 0,
        onHold: 0
    };
      
    await fetch(api_url + `powerbi`, getrequestOptions)
        .then((response) => response.json())
        .then((result) => {           
            res = result?.data;
        })
        .catch((error) => {
            console.log(error);            
        });        
    return res;
}

export const createNewPowerBiEntry = async (powerBIObj: any) => {
    let res;
    const payload = JSON.stringify(powerBIObj)
    
    const postrequestOptions = {
        method: "POST",
        headers: myHeaders,
        body: payload
    };    

    await fetch(api_url + `powerbi`, postrequestOptions)
        .then((response) => response.json())
        .then((result) => {           
            res = result?.data;
        })
        .catch((error) => {
            res = [];
        });        
    return res;
}