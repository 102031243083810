const api_url = process.env.REACT_APP_API_URL;            

const token = localStorage.getItem('token');
const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "token " + token);

const getrequestOptions = {
    method: "GET",
    headers: myHeaders
};




export const fetchAllDepartments = async () => {
    let res;
    await fetch(api_url + `department`, getrequestOptions)
        .then((response) => response.json())
        .then((result) => {           
            res = result;
        })
        .catch((error) => {
            res = [];
        });        
    return res;
    }
    

export const createNewDepartment = async (name: string) => {
    let res;
    const payload = JSON.stringify({        
        "name": name
    })
    
    const postrequestOptions = {
        method: "POST",
        headers: myHeaders,
        body: payload
    };    

    await fetch(api_url + `department`, postrequestOptions)
        .then((response) => response.json())
        .then((result) => {           
            res = result?.data;
        })
        .catch((error) => {
            res = [];
        });        
    return res;
   }