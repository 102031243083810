import * as React from 'react';
import { Avatar, Box, CircularProgress, Grid, Pagination } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { toast } from 'react-toastify';
import NoRecorComponent from '../../../Components/NoRecordComponent';

const CARD_HEIGHT = 200;
const PAGINATION_HEIGHT = 56;
const MARGIN_AND_PADDING = 40;
const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Resource',
  },
  {
    id: 'designation',
    numeric: false,
    disablePadding: false,
    label: 'Designation',
  },
  {
    id: 'stack',
    numeric: false,
    disablePadding: false,
    label: 'Stack',
  },
  {
    id: 'project',
    numeric: false,
    disablePadding: false,
    label: 'Project',
  },
  {
    id: 'engagement',
    numeric: false,
    disablePadding: false,
    label: 'Engagement',
  }
];


export default function ResourcesTable({ resourcesArray }: any) {
  const navigate = useNavigate();
  const [loader, setloader] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(12);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };
  const calculateRowsPerPage = React.useCallback(() => {
    const availableHeight = window.innerHeight - PAGINATION_HEIGHT - MARGIN_AND_PADDING;
    const rows = Math.floor(availableHeight / CARD_HEIGHT);

    return Math.max(1, Math.min(20, rows * 6));
  }, []);

  // Update rows per page when window is resized
  useEffect(() => {
    const handleResize = () => {
      setRowsPerPage(calculateRowsPerPage());

      setPage(1);
    };


    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [calculateRowsPerPage]);

  const [resources, setResourcesData] = useState([
    {
      id: null,
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      employmentStatus: '',
      department: '',
      jobTitle: {
        id: '',
        name: ''
      },
      stacks: '',
      notes: '',
      managerId: '',
      timeZone: ''
    }
  ]);

  const handleResourcePage = (resource: any) => {
    const params = {
      resourceId: resource.id
    };
    localStorage.setItem("previousPage", "/resources");
    const queryString = new URLSearchParams(params).toString();
    navigate(`/resources-detail?${queryString}`);
  };

  useEffect(() => {
    setloader(true);
    setResourcesData(resourcesArray)
    setloader(false);
  }, [resourcesArray])


  function stringAvatar(name: string) {
    return {
      sx: {
        width: 40,
        height: 40,
        bgcolor: '#5257C71A',
        color: '#5257C7B2',
        border: '1px solid #5257C7B2',
        borderRadius: '50%',
        fontSize: '24px',
        fontWeight: '600',
        lineHeight: '19.2px'
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }
  const paginatedData = resourcesArray.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
      {loader ? <CircularProgress size={24} color="inherit" /> :
        <Grid container spacing={1}>
          {resources.length ? paginatedData.map((row: any, index: any) => {
            return (
              <Grid item md={2}>
                <Card className='resource-card' onClick={() => handleResourcePage(row)}>
                  <CardContent>
                    <Avatar {...stringAvatar(`${row.firstName} ${row.lastName}`)} className='avatar-name' />
                    <br></br>
                    <div className='resource-name'>{row.firstName} {row.lastName}</div>
                    <div className='resource-designation'>{row?.jobTitle?.name}</div>
                  </CardContent>
                </Card>

              </Grid>
            )
          }) : <NoRecorComponent />}
        </Grid>}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          marginTop: '20px',
        }}
      >
        <Pagination
          count={Math.ceil(resources.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
          shape="rounded"
          sx={{
            '.Mui-selected': {
              backgroundColor: '#5257C7 !important',
              color: '#fff !important',
            },
            '.MuiPaginationItem-root': {
              '&:hover': {
                backgroundColor: '#5257C71A !important',
              },
            },
          }}

        />

      </Box>


    </div>

  );
}
