import React, { useEffect } from 'react';
// import './resources.css';
import Grid from '@mui/material/Grid';
import ResponsiveAppBar from '../Dashboard/components/appbar';
import DepartmentTable from './Components/departmentTable';
import { Box, Button, CircularProgress, Input, InputAdornment, TextField} from '@mui/material';
import { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import SearchIcon from '@mui/icons-material/Search';
import AddDepartmentAnchorDrawer from './Components/addDepartment';
import { toast } from 'react-toastify';




function Department() {
    const api_url = process.env.REACT_APP_API_URL;            

    const [loader, setloader] = useState(true)
    const [rowsData, setrowsData] = useState([{
        id: '',
        name: '',
        createdAt: '',
        updatedAt: ''
    }]);
    const [mainRowsData, setMainRowsData] = useState([{
        id: '',
        name: '',
        createdAt: '',
        updatedAt: ''
    }]);

    const handleSearchDepartment = (e: any) => {
        let searchDepartmentRow = [];
        let searchVal = e.target.value;
    
        if (searchVal === ""){
          setrowsData(mainRowsData)
        }        
        else{
          for (let i = 0; i < mainRowsData.length; i ++ ){
            if (mainRowsData[i]?.name.toLowerCase().includes(searchVal.toLowerCase()))
                searchDepartmentRow.push(mainRowsData[i])
          }
          
          setrowsData(searchDepartmentRow);
        }
            
    }

    const handleDepartmentDelete = async (targetIndex: any, row: any) => {                        
        const token = localStorage.getItem('token');
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "token " + token);
        
        const requestOptions = {
            method: "DELETE",
            headers: myHeaders,
        };
        console.log(targetIndex, "index");
        
        await fetch(api_url + "department/" + row["id"], requestOptions)
        .then((response) => response.json())
        .then((result) => {        
            
            setrowsData(rowsData.filter((_: any, idx: any) =>  idx !== targetIndex))    
            setMainRowsData(mainRowsData.filter((_: any, idx: any) =>  idx !== targetIndex))    
            toast.success("Deleted Successfully")
            
        }).catch((error) => {
            toast.error("Some issue Occured" + error)
        })
            
            
    }

    const fetchDepartment = async () => {
        const token = localStorage.getItem('token');
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "token " + token);

        const requestOptions = {
            method: "GET",
            headers: myHeaders
          };
          
        await fetch(api_url + "department", requestOptions)
        .then((response) => response.json())
        .then((result) => {            
            setrowsData(result);
            setMainRowsData(result);
            setloader(false)
        }).catch((error) => {
            console.log(error);
        })
    }


    useEffect( () => {
        fetchDepartment();
    }, [])

    const [anchor, setAnchor] = useState(false)
    
      const handleStateAnchor = () => {
        setAnchor(true)
        return true;
      };
  
      const toggleDrawer = () => {
        setAnchor(false);
        return false
        };

 
  return (
    <Grid spacing={0}  className='mainApp'>
        <ResponsiveAppBar />  
        <Box>                
            <Grid>
                <Grid item md={1}></Grid>
                <Grid item md={10}>
                    <Grid container >
                        <Grid item md={7}>
                            <div className='titleBar'>
                                Departments    
                            </div>
                        </Grid>
                        <Grid item md={3}>
                        <TextField
                            className='search-field'
                            placeholder='Search'
                            onChange={handleSearchDepartment}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>,
                                }}
                            />
                            
                        </Grid>
                        
                        <Grid item md={2}>
                            <Button className='add-resource' onClick={handleStateAnchor}>
                                Add Department
                            </Button>
                            <Drawer
                                anchor={'right'}
                                open={anchor}
                                onClose={toggleDrawer}
                            >                                    
                                <AddDepartmentAnchorDrawer toggleDrawer={toggleDrawer} 
                                rows={rowsData} mainRows={mainRowsData} 
                                setRows={setrowsData} setMain={setMainRowsData}
                                setAnchor={setAnchor}/>
                            </Drawer>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={1}></Grid>
            </Grid>
                
        </Box>   


        <Box className="table-section">
            
            <Grid>
                <Grid item md={1}></Grid>
                <Grid item md={10}>
                    <Grid>
                        <Grid item md={12} sm={12} xs={12}>
                        {loader && rowsData ? <div  style={{display:'flex',justifyContent:'center'}}>
                            <CircularProgress size={24} color="inherit" /> 
                        </div>:  <DepartmentTable Departments={rowsData} handleDelete={handleDepartmentDelete}                        
                                    rows={rowsData} mainRows={mainRowsData} 
                                    setRows={setrowsData} setMain={setMainRowsData}
                                />}
                        
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={1}></Grid>
            </Grid>
                
        </Box>                 

    </Grid>

  );
}

export default Department;
