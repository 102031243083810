import * as XLSX from 'xlsx';
const api_url = process.env.REACT_APP_API_URL;            

const token = localStorage.getItem('token');
const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "token " + token);

const getrequestOptions = {
    method: "GET",
    headers: myHeaders
};


export const fetchAllJobTitles = async () => {
    let res;
    await fetch(api_url + `jobTitle`, getrequestOptions)
        .then((response) => response.json())
        .then((result) => {           
            res = result;
        })
        .catch((error) => {
            res = [];
        });        
    return res;
}

export const createNewJobTitle = async (name: string) => {
    let res;
    const payload = JSON.stringify({        
        "name": name
    })
    
    const postrequestOptions = {
        method: "POST",
        headers: myHeaders,
        body: payload
    };    

    await fetch(api_url + `jobTitle`, postrequestOptions)
        .then((response) => response.json())
        .then((result) => {           
            res = result?.data;
        })
        .catch((error) => {
            res = [];
        });        
    return res;
}

export const exportAllJobTitle = async () => {
    await fetch(api_url + `jobTitle/export/`, getrequestOptions)
        .then((response) => response.json())
        .then((result) => {
            const ws = XLSX.utils.json_to_sheet(result);

            // Create a new workbook and append the worksheet to it
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

            // Generate the Excel file as a Blob
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

            // Create a Blob from the Excel buffer and trigger the download
            const file = new Blob([excelBuffer], { type: 'application/octet-stream' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(file);
            link.download = 'JobTitles.xlsx'; // Name of the downloaded file
            link.click();
        })
        .catch((error) => {           
        })
}   
    