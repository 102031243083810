import React from "react";
import "./login.css";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { getLoginUserDetail } from "../../api/loginAPIs/loginAPIs";

export interface LoginResponse {
  user: {
    name: string;
    email: string;
    role: string;
  } | null;
  token: string;
}

function Login() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setloader] = useState(false);

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async () => {
    if (email === "") {
      toast.error("Email shouldn't be empty");
    } else if (password === "") {
      toast.error("Password shouldn't be empty");
    } else {
      setloader(true);
      const result: LoginResponse = await getLoginUserDetail(email, password);

      if (result.user) {
        localStorage.setItem("token", result.token);
        localStorage.setItem("name", result.user?.name);
        localStorage.setItem("email", result.user?.email);
        localStorage.setItem("role", result.user?.role);
        toast.success("Successfully Logged In");
        navigate("/");
      } else {
        toast.error("Invalid Credentials");
        setloader(false);
      }
    }
  };
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };
  return (
    <Grid container className="login">
      <Grid item md={6} sm={12} xs={12} className="textSection"></Grid>

      <Grid item md={6} sm={12} xs={12} className="signInSection">
        <div className="signIn">Welcome Back</div>
        <div className="signIn-desc">Please login to proceed</div>

        <div className="login-section">
          <div className="name-section">
            <div className="label">Email</div>
            <TextField
              className="textfield"
              onChange={handleEmailChange}
              placeholder="Write your Email"
              onKeyDown={handleKeyDown}
              disabled={loader} 
              InputProps={{
                style: {
                  borderRadius: 10,
                },
              }}
            ></TextField>
          </div>

          <div className="password-section">
            <div className="label">Password</div>
            <TextField
              className="textfield"
              type={"password"}
              onChange={handlePasswordChange}
              placeholder="Your Password here"
              onKeyDown={handleKeyDown}
              disabled={loader} 
              InputProps={{
                style: {
                  borderRadius: 10,
                },
              }}
            ></TextField>
          </div>

          <div className="forgot-section">
            <label className="rememberTxt">
              <input type="checkbox" className="checkbox" />
              Remember me
            </label>
            <span className="forgotTxt">Forgot Password?</span>
          </div>
        </div>
        {loader ? (
          <Button className="loginBtn">
            <CircularProgress color="inherit" />
          </Button>
        ) : (
          <Button className="loginBtn" onClick={handleSubmit}>
            Login
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

export default Login;
