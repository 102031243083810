import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


function Protected(props: any) {
  const navigate = useNavigate();
  const { Component, name } = props;

  const adminUrls = ['project-protfolio', 'work-log'];

  useEffect( () => {
    let token  = localStorage.getItem('token');
    let role  = localStorage.getItem('role');

    if (!token){
        navigate('/login')
    }
    if (adminUrls.includes(name) && role !== 'admin'){
      navigate('/NotFoundPage')
    }
  })  

  return (  
    <Component />
  );
}

export default Protected;
