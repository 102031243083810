import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Avatar } from '@mui/material';
import { getBoolenYesOrNO } from '../../../utils/utils.func';


const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Resource',
  },
  {
    id: 'allocation',
    numeric: false,
    disablePadding: false,
    label: 'Allocation',
  },
  {
    id: 'allocated-hours',
    numeric: true,
    disablePadding: false,
    label: 'Allocated Hours',
  },
  {
    id: 'total-spent',
    numeric: true,
    disablePadding: false,
    label: 'Total Spent',
  },
  {
    id: 'billable',
    numeric: true,
    disablePadding: false,
    label: 'Billable',
  },
  {
    id: 'non-billable',
    numeric: true,
    disablePadding: false,
    label: 'Role',
  }
];

function EnhancedTableHead() {

  return (
    <TableHead>
      <TableRow className='table-header'>

        <TableCell className='table-header-cell'
        ></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            className='table-header-cell'
            key={headCell.id}            
            padding={headCell.disablePadding ? 'none' : 'normal'}          
          >
          {headCell.label}
        </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


export default function EnhancedTable({ rowsData}: any) {
  
  function stringAvatar(name: string) {
    return {
      sx: {
        width:40,
        height:40,
        bgcolor: '#5257C71A',
        color: '#5257C7B2',
        border: '1px solid #5257C7B2',
        borderRadius: '50%',
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '19.2px'
      },
      // children: `${name[0]}`,
    };
  }

  return (
    <Box sx={{ width: '100%', borderRadius:"30px" }}>
      <Paper sx={{ width: '100%', mb: 2 , borderRadius:"30px" }}>
        <TableContainer>        
          <Table>                      
            <EnhancedTableHead   />
            <TableBody>
              {rowsData?.Resources?.map((row: any, index: any) => {

                return (
                  <TableRow
                    hover
                    key={row.id}
                    sx={{ cursor: 'pointer',borderBottom: 'none !important' }}                    
                  >
                    <TableCell className='table-cell'></TableCell>
                    <TableCell
                      className='table-cell'
                      component="th"
                      scope="row"
                      padding="none"
                    >
                      <div className='resource-cell'>
                        <Avatar {...stringAvatar(row?.firstName)} />
                        {row?.firstName + " " + row?.lastName}
                      </div>
                    </TableCell>
                    <TableCell className='table-cell'>{row.allocatedHours} hrs</TableCell>
                    <TableCell className='table-cell'>{row['allocated-hours']} hrs</TableCell>
                    <TableCell className='table-cell'>{row['total-spent']} hrs</TableCell>
                    <TableCell className='table-cell'>{getBoolenYesOrNO(row?.Events?.billable)}</TableCell>
                    <TableCell className='table-cell'>{row?.role} </TableCell>                    
                  </TableRow>
                );
              })}
              
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>      
    </Box>
  );
}