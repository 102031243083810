import React from 'react';
import './App.css';
import Login from './pages/login/login';
import Home from './pages/Dashboard/dashboard'
import Resources from './pages/Resources/resources';
import ResourcesDetail from './pages/Resources/ResourceDetail';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Projects from './pages/Projects/projects';
import ProjectsDetail from './pages/Projects/ProjectsDetail';
import Stacks from './pages/Stack/stacks';
import Protected from './Components/protected';
import Schedule from './pages/Schedule/schedule';
import ResourceProjectDetails from './pages/Resources/ResourceProjetcsDetail';
import AddResourcePage from './pages/Resources/Components/addResource';
import Department from './pages/Department/department';
import JobTitle from './pages/JobTitle/JobTitle';
import { WorkLog } from './pages/WorkLog/worklog';
import { ProjectProtfolio } from './pages/ProjectPowerBI/projectPowerBI';
import  NotFoundPage  from './pages/Dashboard/components/notFoundPage';

function App() {
  return (    
    <>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Protected Component={Home} />}/>
        <Route path='/login' element={<Login />}/>        
        <Route path='/resources' element={<Protected Component={Resources}/>}/>
        <Route path='/resources-detail' element={<Protected Component={ResourcesDetail} />}/>
        <Route path='/add-resource' element={<Protected Component={AddResourcePage} />}/>
        <Route path='/update-resource' element={<Protected Component={AddResourcePage} />}/>
        <Route path='/projects' element={<Protected Component={Projects} />}/>
        <Route path='/projects-detail' element={<Protected Component={ProjectsDetail} />}/>
        <Route path='/resource-project-detail' element={<Protected Component={ResourceProjectDetails} />}/>
        <Route path='/stacks' element={<Protected Component={Stacks} />}/>
        <Route path='/department' element={<Protected Component={Department} />}/>
        <Route path='/job-title' element={<Protected Component={JobTitle} />}/>
        <Route path='/schedule' element={<Protected Component={Schedule} />}/>
        <Route path='/work-log' element={<Protected Component={WorkLog} name={"work-log"}/>}/>
        <Route path='/project-protfolio' element={<Protected Component={ProjectProtfolio} name ={"project-protfolio"}/>}/>
        <Route path='/NotFoundPage' element={<Protected Component={NotFoundPage} name ={"NotFoundPage"}/>}/>
      </Routes>
    </BrowserRouter>
    {/* <Login /> */}
    </>
  );
}

export default App;
