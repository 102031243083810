import React from "react"
import ResponsiveAppBar from "../Dashboard/components/appbar"

export function WorkLog () {
    return (
        <div className='mainApp'>
            <ResponsiveAppBar />
            <iframe title="JIRA with Prod_MySQL" width="100%" height="790" 
                src="https://app.powerbi.com/view?r=eyJrIjoiNmIzNDA0MWItNGUxYS00MjFlLTk0MDctNzgzZTcxMGVkMzM3IiwidCI6ImE1MjNlYzQwLTVlYmYtNGRmZC1iNjQwLTQxYmI4YjQ5Nzg1NiIsImMiOjl9"  allowFullScreen>
            </iframe>
        </div>
    )
}