import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CircularProgress, Grid, MenuItem,
    Switch, 
    TextField, Typography} from '@mui/material';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { createNewPowerBiEntry } from '../../../api/powerBIAPIS/powerBiApis';


export default function AddProjectPowerBIProtfolio({ toggleDrawer, projectData, setProjectData }: any) {

  const [loader, setloader] = useState(false);  
  
  const handleAddProject = async () => {    
    setloader(true);
    try{
      const res = await createNewPowerBiEntry(projectData);
      console.log(res);
      toast.success('Project added successfully');
      toggleDrawer();
      // window.location.reload();
    }
    catch(err){
      console.log(err);
      toast.error('Error adding project');
    }
    setloader(false);
  }

  const handleInputChange = (e:any) => {
    const { name, value } = e.target;
    
    setProjectData({
      ...projectData,
      [name]: value,
    });

  };

  return (
    <Box className='add-resource-anchor'>        

        <Box className='resource-form'>
            <section>
              <h3>Projects</h3>

            <Grid container spacing={2}>
              <Grid item md={6}>
                <Box className='fieldBox'>
                    <Typography className='labelText' >Development <span style={{color: 'red'}}>*</span></Typography>  
                    <TextField className="textField" placeholder='Add development' name="development" onChange={handleInputChange}
                          type='number' value={projectData.development}>                            
                    </TextField>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box className='fieldBox'>
                    <Typography className='labelText'>Support <span style={{color: 'red'}}>*</span></Typography>  
                    <TextField className="textField" placeholder='Add support' name="support" onChange={handleInputChange}
                              type='number'  value={projectData.support} >                        
                    </TextField>
                </Box>              
              </Grid>
            </Grid>
            
            </section>
            
            <section>
              <h3>Project Type</h3>

            <Grid container spacing={2}>
              <Grid item md={6}>
                <Box className='fieldBox'>
                    <Typography className='labelText' >Retainer <span style={{color: 'red'}}>*</span></Typography>  
                    <TextField className="textField" placeholder='Add retainer' name="retainer" onChange={handleInputChange}
                          type='number' value={projectData.retainer}>                            
                    </TextField>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box className='fieldBox'>
                    <Typography className='labelText'>Fixed Scope <span style={{color: 'red'}}>*</span></Typography>  
                    <TextField className="textField" placeholder='Add fixed Scope' name="fixedScope" onChange={handleInputChange}
                              type='number'  value={projectData.fixedScope} >                        
                    </TextField>
                </Box>              
              </Grid>
            </Grid>
            
            </section>

            <section>
              <h3>Resources</h3>

            <Grid container spacing={2}>
              <Grid item md={6}>
                <Box className='fieldBox'>
                    <Typography className='labelText' >Allocated <span style={{color: 'red'}}>*</span></Typography>  
                    <TextField className="textField" placeholder='Add allocated' name="allocated" onChange={handleInputChange}
                          type='number' value={projectData.allocated}>                            
                    </TextField>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box className='fieldBox'>
                    <Typography className='labelText'>Benched <span style={{color: 'red'}}>*</span></Typography>  
                    <TextField className="textField" placeholder='Add Benched' name="benched" onChange={handleInputChange}
                              type='number'  value={projectData.benched} >                        
                    </TextField>
                </Box>              
              </Grid>
            </Grid>
            
            </section>

            <section>
              <h3>Discoveries</h3>

            <Grid container spacing={2}>
              <Grid item md={6}>
                <Box className='fieldBox'>
                    <Typography className='labelText' >In Progress <span style={{color: 'red'}}>*</span></Typography>  
                    <TextField className="textField" placeholder='Add inProgress' name="inProgress" onChange={handleInputChange}
                          type='number' value={projectData.inProgress}>                            
                    </TextField>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box className='fieldBox'>
                    <Typography className='labelText'>On Hold <span style={{color: 'red'}}>*</span></Typography>  
                    <TextField className="textField" placeholder='Add on Hold' name="onHold" onChange={handleInputChange}
                              type='number'  value={projectData.onHold} >                        
                    </TextField>
                </Box>              
              </Grid>
            </Grid>
            
            </section>
            
            <Box className='buttonBox'>

                <Button className='add-resource' onClick={handleAddProject} disabled={loader}>
                  {loader ? <CircularProgress size={24} color="inherit" /> : 'Update Values'}
                </Button>
            </Box>
        </Box>

    </Box>
  );
}
