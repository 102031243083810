import React from 'react';
import Box from '@mui/material/Box';


export default function NoRecorComponent(params: any) {
    return (
        <Box sx={{display: { xs: 'none', md: 'flex' }}}>
            <div>
                { params.sch ? <div className='no-record' style={{
                        width: '200px',
                        height: '200px',
                    }}></div> : <div className='no-record' style={{
                        width: '300px',
                        height: '300px',
                        position: 'absolute',
                    }}></div>
                }
            </div>            
        </Box>

    )

}