import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, IconButton, TablePagination } from '@mui/material';
import UpdateProjectAnchorDrawer from './updateProject';
import EditIcon from '@mui/icons-material/Edit';
import { DeleteForever } from '@mui/icons-material';
import { toast } from 'react-toastify';
import NoRecorComponent from '../../../Components/NoRecordComponent';
import { getBoolenActiveOrNOTActive, getDateFromString } from '../../../utils/utils.func';
import Tooltip from '@mui/material/Tooltip';


const headCells = [
  {
    id: 'color',
    numeric: false,
    disablePadding: true,
    label: '',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    label: 'Code',
  },
  {
    id: 'startDate',
    numeric: false,
    disablePadding: false,
    label: 'Start Date',
  },
  {
    id: 'endDate',
    numeric: false,
    disablePadding: false,
    label: 'End Date',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'manager',
    numeric: false,
    disablePadding: false,
    label: 'Project Manager',
  },
  {
    id: 'team',
    numeric: false,
    disablePadding: false,
    label: 'Team Lead',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
  }

];


export default function ProjectsTable({ projectsArray, updateProjectList, manager_list }: any) {
  const navigate = useNavigate();
  const [loader, setloader] = useState(false);
  const [anchor, setAnchor] = useState(false);
  const [updateProject, setUpdateProject] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProject, setSelectedProject] = useState<any>(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [projects, setProjectsData] = useState([
    {
      id: null,
      projectName: "",
      projectCode: "",
      startDate: "",
      endDate: "",
      isArchived: false,
      billable: false,
      details: "",
      pm_id: null,
      tl_id: null,
      isActive: true,
      colorCode: "",
      project_manager: {
        email: "",
        firstName: "",
        id: '',
        lastName: ""
      },
      team_lead: {
        email: "",
        firstName: "",
        id: '',
        lastName: ""
      }

    }
  ]);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when rows per page changes
  };

  const paginatedProjects = React.useMemo(() => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return projects?.slice(startIndex, endIndex);
  }, [projects, page, rowsPerPage]);
  const handleProjectDetailPage = (row: any) => {
    const params = {
      projectId: row.id
    };
    const queryString = new URLSearchParams(params).toString();
    navigate(`/projects-detail?${queryString}`)
  }

  useEffect(() => {
    setloader(true);
    setProjectsData(projectsArray)
    setloader(false);
  }, [projectsArray])

  const handleStateAnchor = (data: any) => {
    setUpdateProject(data)
    setAnchor(true)
    return true;
  };

  const toggleDrawer = () => {
    setAnchor(false);
    updateProjectList()
    return false
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleDelete = async () => {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "token " + token);

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
    };
    setloader(true);
    const api_url = process.env.REACT_APP_API_URL;
    await fetch(api_url + `projects/${selectedProject.id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        toast.success("Project Deleted Successfully")
        setloader(false)
        setOpenDialog(false);
        updateProjectList()
      })
      .catch((error) => {
        toast.error(error)
        setloader(false)
        setOpenDialog(false);
      });
  };

  function fetchFullName(obj: any) {
    if (obj?.firstName) {
      return obj?.firstName + " " + obj?.lastName
    }
    else {
      return "Not Selected"
    }
  }
  function EnhancedTableHead() {

    return (
      <TableHead>
        <TableRow className='table-header'>

          <TableCell className='table-header-cell'
          ></TableCell>
          {headCells.map((headCell) => (
            <TableCell
              className='table-header-cell'
              key={headCell.id}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Box sx={{ width: '100%', borderRadius: "30px" }}>
      {loader ? <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress size={24} color="inherit" />
      </div> :
        <Paper sx={{ width: '100%', mb: 2, borderRadius: "30px" }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 1000, borderRadius: "30px" }}
              aria-labelledby="tableTitle"
              size={'medium'}
            >
              {projects.length ? (
                <>
                  <EnhancedTableHead />
                  <TableBody>
                    {paginatedProjects?.map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          key={row.id}
                          sx={{ cursor: 'pointer', borderBottom: 'none !important' }}
                          style={{ marginRight: index === 0 ? 8 : 0 }}
                        >
                          <TableCell className='table-cell-row'></TableCell>
                          <TableCell className='table-cell-row'>
                            <div
                              style={{
                                width: '20px',
                                height: '20px',
                                borderRadius: '50%',
                                backgroundColor: row.colorCode, // Assuming `colorCode` is the property holding the color
                                marginRight: '10px',
                              }}
                            />
                          </TableCell>
                          <TableCell
                            className='table-cell-row-name'
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            onClick={() => handleProjectDetailPage(row)}
                          >
                            <div>
                              {row.projectName}
                            </div>
                          </TableCell>
                          <TableCell className='table-cell-row'>{row.projectCode} </TableCell>
                          <TableCell className='table-cell-row'>{getDateFromString(row.startDate)} </TableCell>
                          <TableCell className='table-cell-row'>{getDateFromString(row.endDate)}</TableCell>
                          <TableCell className='table-cell-row'>{getBoolenActiveOrNOTActive(row.isActive)}</TableCell>
                          <TableCell className='table-cell-row'>{fetchFullName(row?.project_manager)} </TableCell>
                          <TableCell className='table-cell-row'>{fetchFullName(row?.team_lead)} </TableCell>
                          <TableCell className='table-cell-row'>

                            <Tooltip title="Edit">
                              <IconButton onClick={() => handleStateAnchor(row)}>
                                <EditIcon />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Delete">
                              <IconButton onClick={() => {
                                setSelectedProject(row)
                                setOpenDialog(true);
                              }}>
                                <DeleteForever />
                              </IconButton>
                            </Tooltip>

                          </TableCell>
                        </TableRow>
                      );
                    })}

                  </TableBody>
                </>
              ) : (
                <NoRecorComponent />
              )}

            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 30]}
              component="div"
              count={projects.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
          <Drawer
            anchor={'right'}
            open={anchor}
            onClose={toggleDrawer}
          >
            <UpdateProjectAnchorDrawer toggleDrawer={toggleDrawer} updateProjectData={updateProject} manager_list={manager_list} />
          </Drawer>
          {/* Delete Confirmation Dialog */}
          <Dialog
            open={openDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete the project "{selectedProject?.projectName}"?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDelete} color="error" autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>

        </Paper>}
    </Box>
  );
}
