import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Avatar, Tab, TablePagination, Tabs } from '@mui/material';
import { getFullName } from '../../../utils/utils.func';
import { useNavigate } from 'react-router-dom';



const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Resource',
  },
  {
    id: 'department',
    numeric: true,
    disablePadding: false,
    label: 'Department',
  },
  {
    id: 'jobtitle',
    numeric: true,
    disablePadding: false,
    label: 'Job Title',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Employment Status',
  },
  {
    id: 'stack',
    numeric: true,
    disablePadding: false,
    label: 'Stacks',
  },
  {
    id: 'manager',
    numeric: true,
    disablePadding: false,
    label: 'Manager',
  },
  // {
  //   id: 'billable',
  //   numeric: true,
  //   disablePadding: false,
  //   label: 'Billable',
  // },
];
const bufferExtraColumn = {
  id: 'Allocation',
  numeric: true,
  disablePadding: false,
  label: 'Allocation',
};
const partiallyBillableExtraColumn = {
  id: 'billable',
  numeric: true,
  disablePadding: false,
  label: 'Billable',
};

const tabData = [
  { value: "Free Resource", label: "Free Resource" },
  { value: "Buffer", label: "Buffer" },
  { value: "Partially Billable", label: "Partially Billable" },
];



function EnhancedTableHead({ selectedTab }: { selectedTab: string }) {
  const headCells2: any = React.useMemo(() => {
    if (selectedTab === "Buffer") {
      return [...headCells, bufferExtraColumn];
    } else if (selectedTab === "Partially Billable") {
      return [...headCells, partiallyBillableExtraColumn];
    } else {
      return headCells;
    }
  }, [selectedTab]);

  return (
    <TableHead>
      <TableRow className='table-header'>

        <TableCell className='table-header-cell'
        ></TableCell>
        {headCells2.map((headCell: any) => (
          <TableCell
            className='table-header-cell'
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


export default function EnhancedTable({ response }: any) {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(tabData[0].value);
  const [tab, setTab] = React.useState(0);
  const [rowsPerTab, setRowsPerTab] = React.useState(10)


  const filteredData = React.useMemo(() => {
    if (!response) return [];

    switch (value) {
      case "Free Resource":
        return response?.free || [];
      case "Buffer":
        return response?.buffer || [];
      case "Partially Billable":
        return response?.partially || [];
      default:
        return [];
    }
  }, [value, response]);


  const displayedData = React.useMemo(() => {
    const startIndex = tab * rowsPerTab;
    return filteredData.slice(startIndex, startIndex + rowsPerTab);
  }, [filteredData, tab, rowsPerTab]);


  React.useEffect(() => {
    setTab(0);
  }, [value]);

  const handleChangeTab = (event: unknown, newTab: number) => {
    setTab(newTab);
  };

  const handleChangeRowsPerTab = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerTab(parseInt(event.target.value, 10));
    setTab(0);
  };


  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setTab(0)
    setRowsPerTab(10)
  };
  const tabsStyles = {
    display: "flex",
    justifyContent: "center",
    "& .MuiTabs-flexContainer": {

      marginLeft: "12px",
      gap: "8px",
    },
    "& .MuiTab-root": {
      margin: "  10px",
      fontFamily: '"Open Sans", serif',
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "33.6px",
      letterSpacing: "-0.05em",
      color: "#676DFA",
      textTransform: "none",
      borderRadius: "8px",
      padding: "8px 16px",
      transition: "all 0.3s ease",
      "&:hover": {
        backgroundColor: "#5257C71A",
      },
      "&.Mui-selected": {
        color: "#fff",
        backgroundColor: "#676DFA",
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  }
  function EnhancedTableToolbar() {

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "96%",
        }}

      >
        {/* Tabs Component */}
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="dynamic tabs with button style"
          sx={tabsStyles} // Your custom styles for the tabs
        >
          {tabData.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
            />
          ))}
        </Tabs>

        {/* Typography for Data Count */}
        <Typography
          variant="h6"
          id="tableTitle"
          component="div"
          className='table-text'>
          Total : <span style={{ color: "red" }}>{filteredData.length}</span>
        </Typography>
      </Box>


    );
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        width: 40,
        height: 40,
        bgcolor: '#5257C71A',
        color: '#5257C7B2',
        border: '1px solid #5257C7B2',
        borderRadius: '50%',
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '19.2px'
      },
    };
  }

  const handleResourcePage = (resource: any) => {
    const params = {
      resourceId: resource.id
    };

    localStorage.setItem("previousPage", "/dashboard");
    const queryString = new URLSearchParams(params).toString();
    navigate(`/resources-detail?${queryString}`);
  };

  return (
    <Box sx={{ width: '100%', borderRadius: "30px" }}>
      <Paper sx={{ width: '100%', mb: 2, borderRadius: "30px" }}>
        <EnhancedTableToolbar />
        <TableContainer>
          <Table
            sx={{ minWidth: 1000, borderRadius: "30px" }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead selectedTab={value} />
            <TableBody>
              {displayedData?.map((row: any, index: any) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    key={row.id}
                    onClick={() => handleResourcePage(row)}
                    sx={{ cursor: 'pointer', borderBottom: 'none !important' }}
                  >
                    <TableCell className='table-cell'></TableCell>
                    <TableCell
                      className='table-cell'
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"

                    >
                      <div className='resource-cell'>
                        <Avatar {...stringAvatar(row.name)} />
                        {row.firstName + " " + row.lastName}
                      </div>
                    </TableCell>
                    <TableCell className='table-cell'>{row?.department?.name} </TableCell>
                    <TableCell className='table-cell'>{row?.jobTitle?.name} </TableCell>
                    <TableCell className='table-cell'>{row?.employmentStatus} </TableCell>
                    <TableCell className='table-cell'>
                      {row?.Stacks?.map((val: any) => <span className='stack-value'>{val?.name}</span>)}
                    </TableCell>
                    <TableCell className='table-cell'>{getFullName(row?.manager)}</TableCell>
                    {value === "Buffer" && (

                      <TableCell className='table-cell'>
                        <span className='percentage-value'>
                          {row?.billableHours != null ? `${row?.billableHours} hrs` : '0 hrs'}
                        </span>
                      </TableCell>
                    )}
                    {value === "Partially Billable" && (

                      <TableCell className='table-cell'>
                        <span className='percentage-value'>
                          {row?.billableHours != null ? `${row?.billableHours} %` : '0 %'}
                        </span>
                      </TableCell>
                    )}

                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 30]}
            component="div"
            count={filteredData?.length}
            rowsPerPage={rowsPerTab}
            page={tab}
            onPageChange={handleChangeTab}
            onRowsPerPageChange={handleChangeRowsPerTab}
          />
        </TableContainer>
      </Paper>
    </Box>
  );
}
