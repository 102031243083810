import * as React from 'react';
import Box from '@mui/material/Box';
import ResponsiveAppBar from '../../Dashboard/components/appbar';
import { Button, CircularProgress, FormControl, FormHelperText, Grid, 
  MenuItem, Paper, Select, TextField, Autocomplete, 
  Switch,
  Input} from '@mui/material';
import { toast } from 'react-toastify';
import { Modal, Fade, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchAllDepartments, createNewDepartment } from '../../../api/departmentAPIs/departmentApis';
import { fetchAllJobTitles, createNewJobTitle } from '../../../api/jobTitleAPIs/jobTitleApis';
import { fetchAllStacks, createNewStack } from '../../../api/stackAPIs/stackApis';
import { fetchAllManagers } from '../../../api/resourcesAPIs/resourceApis';
import { getOnlyDatefromDateString } from '../../../utils/utils.func';

export default function AddResourcePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [stackName, setStackName] = React.useState<string[]>([]);
  const [departmentList, setDepartmentList] = React.useState([{
    id: "",
    name: ""
  }]);  
  const [jobTitleList, setJobTitleList] = React.useState([{
    id: "",
    name: ""
  }]);
  const [stackList, setStackList] = React.useState([{
    id: "",
    name: ""
  }]);  

  const [managersList, setManagersList] = React.useState([{
    id: "",
    firstName: "",
    lastName: ""
  }]); 

  const [loader, setloader] = useState(false);
  const api_url = process.env.REACT_APP_API_URL;
  const [resourceData, setResourceData] = useState({
    id:"",
    firstName:"",
    lastName:"",
    email:"",
    gender: "",
    employmentStatus:"",
    department:{
      id: "",
      name: ""
    },
    jobTitle:{
      id: "",
      name: ""
    },
    Stacks:[],
    stacks: "",
    totalHours:"",
    freeHours:"",
    isLead: false,
    dateOfJoining:"",    
    notes:"",
    manager_id:"",
    timeZone:"",
    userID: "",
  });

  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    gender: false,
    employmentStatus: false, // Add for Select fields
    department: false,
    jobTitle: false,
    stack: false, 
    totalHours: false,
    dateOfJoining:false,
    timeZone: false,
    manager_id: false,
    userID: false,
  });

  const timeZone_list = [
    "Pakistan Standard Time", "Australia(UTC +10:30)", "Asia(UTC +4:30)", "North America(UTC -3:30)"
  ]

  const employement_list = [
    "Probation", "Permanent", "Notice Period", "Contract"
  ]
  

  const handleAddResource = async () => {
    if (validateFields()) {
      const token = localStorage.getItem('token');
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "token " + token);

      const payload = JSON.stringify({
        "firstName":resourceData.firstName,
        "lastName":resourceData.lastName,
        "email":resourceData.email,
        "gender":resourceData.gender,
        "employmentStatus":resourceData.employmentStatus,
        "departmentId":resourceData.department.id,
        "jobTitleId":resourceData.jobTitle.id,
        "stacks":resourceData.Stacks,
        "totalHours":resourceData.totalHours,
        "freeHours":resourceData.totalHours,
        "dateOfJoining":resourceData.dateOfJoining,
        "isLead": resourceData.isLead,
        "notes":resourceData.notes,
        "manager_id":resourceData.manager_id,
        "timeZone":resourceData.timeZone,
        "userID":resourceData.userID
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: payload,
      };
      setloader(true);
      await fetch(api_url + "resource", requestOptions)
      .then((response) => response.json())
      .then((result) => {
          if (result.status === "error"){
            toast.error(result.message)
            setloader(false)  
          }
          else{
            console.log(result.id);          
            toast.success("Resource Added Successfully")
            setloader(false)
            navigate('/resources');
          }
      })
      .catch((error) => {
          toast.error(error)
          setloader(false)
      });      
    }
  }

  const handleUpdateResource = async () => {
    if (validateFields()) {
      const token = localStorage.getItem('token');
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "token " + token);
  
      const payload = JSON.stringify({
        "id":resourceData.id,
        "firstName":resourceData.firstName,
        "lastName":resourceData.lastName,      
        "gender":resourceData.gender,
        "employmentStatus":resourceData.employmentStatus,
        "departmentId":resourceData.department.id,
        "jobTitleId":resourceData.jobTitle.id,
        "stacks": resourceData.Stacks,
        "totalHours":resourceData.totalHours,
        "dateOfJoining":resourceData.dateOfJoining,
        "isLead": resourceData.isLead,
        "notes":resourceData.notes,
        "manager_id":resourceData.manager_id,
        "timeZone":resourceData.timeZone,
        "userID":resourceData.userID,
      });
      
      const requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: payload,
      };
      setloader(true);
      const api_url = process.env.REACT_APP_API_URL
      await fetch(api_url + `resource/${resourceData.id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
          console.log(result);
          toast.success("Resource Updated Successfully")
          setloader(false)
          navigate('/resources-detail?resourceId=' + resourceData?.id);
      })
      .catch((error) => {
          toast.error(error)
          setloader(false)
      });
    }
  }

  const token = localStorage.getItem('token');
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "token " + token);

  const getrequestOptions = {
    method: "GET",
    headers: myHeaders
  };

  const fetchDepartments = async () => {
    const result = await fetchAllDepartments();
    if (result){
      setDepartmentList(result)
    }
  }

  const fetchJobTitles = async () => {
    const result = await fetchAllJobTitles();
    if (result){
      setJobTitleList(result)
    }
  }

  const fetchStacks = async () => {
    const result = await fetchAllStacks();
    if (result){
      setStackList(result)
    }
  }

  const fetchManagers = async () => {
    const result = await fetchAllManagers();
    if (result){
      setManagersList(result)
    }
  }

  const handleOnChangeDepartment = (val: any) => {     
       
    if (val ===null){
      setResourceData({
        ...resourceData,
        department : {
          id: "",
          name: ""
        }
      })
    }else{
      setResourceData({
        ...resourceData,
        department : {
          id: val?.id,
          name: val?.name
        }
      })   
    } 
  }

  const handleChangeLead = (e: any) => {
    
    setResourceData({
      ...resourceData,
      isLead: e.target.checked
    });  
  }

  const handleOnChangeJobTitle = (val: any) => {    
    if (val === null){
      setResourceData({
        ...resourceData,
        jobTitle : {
          id: "",
          name: ""
        }
      })
    }else{
      setResourceData({
        ...resourceData,
        jobTitle : {
          id: val?.id,
          name: val?.name
        }
      })   
    } 
    
  }

  const handleOnChangeStacks = (val: any) => {    
    
    setResourceData({
      ...resourceData,
      Stacks : val
    })    
  }

  const handleInputChange = (e:any) => {
    const { name, value } = e.target;
    if (name  === "totalHours"){
      if (value > 8){
        toast.error("Hours exceeding limit then 8")        
      }
      else{
        setResourceData({
          ...resourceData,
          [name]: value,
        });
      }
    }
    else{
      let udpate_val = value;
      if(value === null || value === ''){
        udpate_val = '';      
      }    
      setResourceData({
        ...resourceData,
        [name]: udpate_val,
      });
    }    
        
  };

  
  const [openModal, setOpenModal] = React.useState(false);
  
  const handleClose = () => setOpenModal(false);
  

  const AddNewModalComp = (props: any) => {
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      borderRadius: 10,
      p: 4,
    };

    const [newVar, setNewVar] = useState("");

    const handleChangeAddNew = (e: any) => {
      setNewVar(e.target.value);
    }

    const handleAddNewOption = async () => {
      if (modalType === "Department"){
        const result = await createNewDepartment(newVar);
        if (result){
          let new_list = departmentList;
          new_list.push({
            id: result["id"],
            name: newVar
          })
          setDepartmentList(new_list)
          toast.success("Added Successfully")
        }
      }
      else if(modalType === "Job Title"){
        const result = await createNewJobTitle(newVar);
        if (result){
          let new_list = jobTitleList;
          new_list.push({
            id: result["id"],
            name: newVar
          })
          setJobTitleList(new_list)
          toast.success("Added Successfully")
        }
      }
      else if(modalType === "Stack"){
        const result = await createNewStack(newVar);
        if (result){
          let new_list = stackList;
          new_list.push({
            id: result["id"],
            name: newVar
          })
          setStackList(new_list)
          toast.success("Added Successfully")
        }
      }

      handleClose();
      
    }

    return (
        <Modal     
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"                  
          open={openModal}
          onClose={handleClose}
          closeAfterTransition                        
          >
          <Fade in={openModal}>
              <Box sx={style}>
              <Typography id="spring-modal-title" variant="h6" component="h2">
                  Add new {modalType}
              </Typography>

              <TextField id="outlined-basic" className='field-update' variant="outlined"                  
                  onChange={handleChangeAddNew} value={newVar}
                  />
              <Button className='add-resource' onClick={handleAddNewOption}>Add +</Button>
              </Box>
          </Fade>
      </Modal>
      );
    
  }

  const [modalType, setModalType] = useState("")

  const handleOpenAddNewModal = (val: string) => {
    setModalType(val);
    setOpenModal(true);
  }

  useEffect(() => {
    if (location.pathname.includes('update-resource')) {
      setResourceData(location.state)
      console.log(resourceData);
      
      // setStackName([resourceData.stacks])
    }    
    fetchDepartments();
    fetchJobTitles();
    fetchStacks();
    fetchManagers();
  }, [location.pathname]);

  const validateFields = () => {
   var today = new Date();
   var doJ = new Date(resourceData?.dateOfJoining);

   console.log(today, doJ);
   
    const newErrors = {
      firstName: resourceData.firstName.trim() === '',
      lastName: resourceData.lastName.trim() === '',
      email: !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(resourceData.email),      
      gender: resourceData?.gender.trim() === '',
      employmentStatus: resourceData.employmentStatus.trim() === '', // For Select validation
      department: resourceData.department?.name.trim() === '',
      jobTitle: resourceData.jobTitle?.name.trim() === '',
      stack: resourceData?.Stacks.length === 0, // If stack is required and no values selected
      totalHours: resourceData?.totalHours === '',
      dateOfJoining: resourceData.dateOfJoining === '' && doJ >= today,
      timeZone: resourceData.timeZone.trim() === '',
      manager_id: resourceData.manager_id === '',
      userID: resourceData?.userID === '',
    };

  

    // if (doJ <= today){
    //   console.log("less");      
    //   newErrors["dateOfJoining"] = true;
    // }    
    setErrors(newErrors);
    return !Object.values(newErrors).some(error => error);
  };

  return (
    <Grid spacing={0}  className='mainApp'>
          <ResponsiveAppBar />  
            <Box>                
                <Grid>
                    <Grid item md={1}></Grid>
                    <Grid item md={10}>
                        <Grid container >
                            <Grid item md={7}>
                                {location.pathname.includes('add-resource') ? <div className='titleBar'>
                                    Add New Resource
                                </div> :
                                <div className='titleBar'>
                                    Update Resource
                                </div>}
                            </Grid>

                            <Grid item md={5}>
                              {/* <Button className='add-resource' onClick={handleAddResource} style={{width: "30%", float: "right"}}>
                                   Save
                              </Button> */}
                              {location.pathname.includes('add-resource') ? <Button className='add-resource' onClick={handleAddResource} style={{width: "30%", float: "right"}} disabled={loader}>
                                {loader ? <CircularProgress size={24} color="inherit" /> : 'Save'}
                              </Button> :
                              <Button className='add-resource' onClick={handleUpdateResource} style={{width: "30%", float: "right"}} disabled={loader}>
                                {loader ? <CircularProgress size={24} color="inherit" /> : 'Update'}
                              </Button>}
                            </Grid>
                            
                        </Grid>
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
                    
            </Box>  


            <Box sx={{ width: '100%',}}>
              <Paper sx={{ width: '100%', mb: 2 , borderRadius:"10px"}}>
                
                <Box className='rsc-info-sec'>
                  <div className='box-heading'>Personal Information</div>

                  <Grid container style={{marginTop: "30px"}}>
                    <Grid item md={3}>
                        <div className='box-label'>First Name <span style={{color: 'red'}}>*</span></div>
                        <TextField className='box-textfield' placeholder='First Name' name="firstName" onChange={handleInputChange}
                        value={resourceData.firstName} error={errors.firstName}
                        helperText={errors.firstName && "First Name is required"}></TextField>                        
                    </Grid>
                    <Grid item md={3}>
                        <div className='box-label'>Last Name  <span style={{color: 'red'}}>*</span></div>
                        <TextField className='box-textfield' placeholder='Last Name' name="lastName" onChange={handleInputChange}
                        error={errors.lastName}
                        value={resourceData.lastName}
                        helperText={errors.lastName && "Last Name is required"}></TextField>
                    </Grid>
                    <Grid item md={3}>
                        <div className='box-label'>Email  <span style={{color: 'red'}}>*</span></div>
                        {location.pathname.includes('add-resource') ? 
                          <TextField className='box-textfield' placeholder='Email' name="email" onChange={handleInputChange}
                          value={resourceData.email}       error={errors.email}
                          helperText={errors.email && "Invalid email format"}></TextField>
                        :
                          <TextField className='box-textfield' placeholder='Email' name="email" disabled
                            value={resourceData.email}       error={errors.email}
                            helperText={errors.email && "Invalid email format"}></TextField>
                        }
                    </Grid>
                    <Grid item md={3}>
                        <div className='box-label'>Gender  <span style={{color: 'red'}}>*</span></div>
                        <FormControl
                              
                              error={errors.gender} // Show error for Select
                              fullWidth
                            >
                              <Select
                            className="box-textfield" name="gender" onChange={handleInputChange}
                            value={resourceData.gender}
                        >
                            <MenuItem value={'Male'}>Male</MenuItem>
                            <MenuItem value={'Female'}>Female</MenuItem>
                        </Select> 
                        {errors.gender && (
                          <FormHelperText>Gender is required</FormHelperText>
                        )}
                        </FormControl>
                                              
                    </Grid>
                  </Grid>
                </Box>

                <Box className='rsc-info-sec'>
                  <div className='box-heading'>Employement Information</div>

                  <Grid container style={{marginTop: "30px"}}>
                    <Grid item md={3}>
                        <div className='box-label'>Employement Status  <span style={{color: 'red'}}>*</span></div>
                        <FormControl
                              className="box-textfield"
                              error={errors.employmentStatus} // Show error for Select
                              fullWidth
                            >
                              <Select
                            className="box-textfield" name="employmentStatus" onChange={handleInputChange}
                            value={resourceData.employmentStatus}
                        >
                            {employement_list.map(val => <MenuItem value={val}>{val}</MenuItem>)}
                        </Select> 
                        {errors.employmentStatus && (
                          <FormHelperText>Employment Status is required</FormHelperText>
                        )}
                        </FormControl>
                                              
                    </Grid>
                    <Grid item md={3}>
                        <div className='box-label'>Department  <span style={{color: 'red'}}>*</span></div>
                        <FormControl
                          className="box-textfield"
                          error={errors.department} // Show error for Select
                          fullWidth
                        >                        
                          <Autocomplete
                            sx={{ width: 300 }}
                            options={departmentList}
                            autoHighlight
                            getOptionLabel={(option) => option?.name}
                            value={resourceData?.department}
                            onChange={(_, newValue) => handleOnChangeDepartment(newValue)}                            
                            renderOption={(props, option) => {
                              const { key, ...optionProps } = props;
                              return (
                                <Box
                                  key={key}
                                  component="li"
                                  {...optionProps}
                                >
                                  {option?.name}
                                </Box>
                              );
                            }}  
                            renderInput={(params) => (
                              <TextField
                                className="box-textfield" name="department"                                  
                                // onChange={handleInputChange}
                                {...params}                               
                              />
                            )}
                            PaperComponent={({ children }) => {
                              return (
                                <Paper>
                                  {children}
                                  <Button
                                    color="primary"
                                    fullWidth
                                    sx={{ justifyContent: "flex-start", pl: 2 }}
                                    onMouseDown={() => {
                                      console.log("Add new");
                                      handleOpenAddNewModal("Department")
                                    }}
                                  >
                                    + Add New
                                  </Button>
                                </Paper>
                              );
                            }}
                          />            
                          <AddNewModalComp />              
                          {errors.department && (
                            <FormHelperText>Department is required</FormHelperText>
                          )}  
                        </FormControl>
                    </Grid>
                    <Grid item md={3}>
                        <div className='box-label'>Job Title  <span style={{color: 'red'}}>*</span></div>
                        <FormControl
                          className="box-textfield"
                          error={errors.jobTitle} // Show error for Select
                          fullWidth
                        >
                          <Autocomplete
                            sx={{ width: 300 }}
                            options={jobTitleList}
                            autoHighlight
                            getOptionLabel={(option) => option?.name}
                            value={resourceData?.jobTitle}
                            onChange={(_, newValue) => handleOnChangeJobTitle(newValue)}
                            renderOption={(props, option) => {
                              const { key, ...optionProps } = props;
                              return (
                                <Box
                                  key={key}
                                  component="li"
                                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                  {...optionProps}
                                >                                  
                                  {option?.name}
                                </Box>
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                className="box-textfield" name="jobTitle" 
                                // onChange={handleInputChange}
                                {...params}                               
                              />
                            )}
                            PaperComponent={({ children }) => {
                              return (
                                <Paper>
                                  {children}
                                  <Button
                                    color="primary"
                                    fullWidth
                                    sx={{ justifyContent: "flex-start", pl: 2 }}
                                    onMouseDown={() => {
                                      handleOpenAddNewModal("Job Title")
                                    }}
                                  >
                                    + Add New
                                  </Button>
                                </Paper>
                              );
                            }}
                          />
                          {errors.jobTitle && (
                            <FormHelperText>Job Title is required</FormHelperText>
                          )}
                        </FormControl>
                          
                    </Grid>
                    <Grid item md={3}>
                        <div className='box-label'>Stack  <span style={{color: 'red'}}>*</span></div>
                        <FormControl
                          className="box-textfield"
                          error={errors.stack} // Show error for Select
                          fullWidth
                        >
                           <Autocomplete
                              multiple
                              id="tags-standard"
                              options={stackList}
                              getOptionLabel={(option) => option.name}                              
                              value={resourceData?.Stacks}
                              onChange={(_, newValue) => handleOnChangeStacks(newValue)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className="box-textfield" name="stacks"                                   
                                />
                              )}
                              PaperComponent={({ children }) => {
                                return (
                                  <Paper>
                                    {children}
                                    <Button
                                      color="primary"
                                      fullWidth
                                      sx={{ justifyContent: "flex-start", pl: 2 }}
                                      onMouseDown={() => {
                                        handleOpenAddNewModal("Stack")
                                      }}
                                    >
                                      + Add New
                                    </Button>
                                  </Paper>
                                );
                              }}
                            />
                      {errors.stack && <FormHelperText>At least one stack is required</FormHelperText>}
                      </FormControl>

                    </Grid>
                    <Grid item md={3}>
                        <div className='box-label' style={{marginTop:'12px'}}>User ID  <span style={{color: 'red'}}>*</span></div>
                        {location.pathname.includes('add-resource') ?
                        <TextField className='box-textfield' type='number' name="userID" onChange={handleInputChange} 
                        value={resourceData.userID}       error={errors.userID} inputProps={{ maxLength: 8 }}
                        helperText={errors.userID && "User ID is required"}></TextField>
                        :
                        <TextField className='box-textfield' type='number' name="userID" 
                        value={resourceData.userID} disabled
                        ></TextField>
                        }
                    </Grid>
                    <Grid item md={3}>
                        <div className='box-label' style={{marginTop:'12px'}}>Total Hours (Per Day)  <span style={{color: 'red'}}>*</span></div>
                        {location.pathname.includes('add-resource') ?
                        <TextField className='box-textfield' type='number' name="totalHours" onChange={handleInputChange} 
                        value={resourceData.totalHours}       error={errors.totalHours} inputProps={{ maxLength: 8 }}
                        helperText={errors.totalHours && "Invalid Hours (Hours cannot exceed more then 8)"}></TextField>
                        :
                        <TextField className='box-textfield' type='number' name="totalHours" 
                        value={resourceData.totalHours} disabled
                        ></TextField>
                        }
                    </Grid>
                    <Grid item md={2.8}>
                        <div className='box-label' style={{marginTop:'12px'}}>Date of Joining  <span style={{color: 'red'}}>*</span></div>  
                        <TextField className="box-textfield" type='date' name="dateOfJoining" onChange={handleInputChange}
                              value={getOnlyDatefromDateString(resourceData?.dateOfJoining)}
                              error={errors.dateOfJoining}
                              helperText={errors.dateOfJoining ? "Invalid Date format" : ""}></TextField>
                    </Grid>
                    <Grid item md={2.8} style={{ marginLeft: '22px'}}>
                        <div className='box-label' style={{marginTop:'12px'}}>Is Lead?</div>  
                        <Switch className='box-checkbox' name="gilad" checked={resourceData?.isLead} 
                          onChange={handleChangeLead} />
                    </Grid>
                  </Grid>
                </Box>


                <Box className='rsc-info-sec'>
                  <div className='box-heading'>Manager and Time Zone</div>

                  <Grid container style={{marginTop: "30px"}}>
                    <Grid item md={6}>
                        <div className='box-label'>Time Zone  <span style={{color: 'red'}}>*</span></div>
                        <FormControl
                              className="box-textfield"
                              error={errors.timeZone} // Show error for Select
                              fullWidth
                            >
                        <Select
                            className="box-textfield" name="timeZone" onChange={handleInputChange}
                            value={resourceData.timeZone}
                        >
                            {timeZone_list.map(val => <MenuItem value={val}>{val}</MenuItem>)}
                        </Select> 
                        {errors.timeZone && (
                            <FormHelperText>Time Zone is required</FormHelperText>
                          )}
                        </FormControl>                      
                    </Grid>
                    <Grid item md={6}>
                        <div className='box-label'>Manager  <span style={{color: 'red'}}>*</span></div>
                        <FormControl
                              className="box-textfield"
                              error={errors.manager_id} // Show error for Select
                              fullWidth
                            >
                        <Select
                            className="box-textfield" name="manager_id" onChange={handleInputChange}
                            value={resourceData.manager_id}
                        >
                            {managersList.map(val => <MenuItem value={val.id}>{val.firstName + " " + val.lastName}</MenuItem>)}
                        </Select>
                        {errors.manager_id && (
                            <FormHelperText>Manager is required</FormHelperText>
                          )}   
                        </FormControl>
                    </Grid>
                   </Grid>
                </Box>
              </Paper>

              <Grid container>
                    <Grid item md={7}></Grid>
                    <Grid item md={5}>
                    {location.pathname.includes('add-resource') ? <Button className='add-resource' onClick={handleAddResource} style={{width: "30%", float: "right"}} disabled={loader}>
                      {loader ? <CircularProgress size={24} color="inherit" /> : 'Save'}
                    </Button> :
                    <Button className='add-resource' onClick={handleUpdateResource} style={{width: "30%", float: "right"}} disabled={loader}>
                      {loader ? <CircularProgress size={24} color="inherit" /> : 'Update'}
                    </Button>}
                  </Grid>

                  </Grid>
            </Box>
        </Grid> 


  )    
}