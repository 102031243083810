import React, { useEffect, useState } from 'react';
import './dashboard.css';
import Grid from '@mui/material/Grid';
import  ResponsiveAppBar  from './components/appbar';
import ProjectsCard from './components/projectsCard';
import EnhancedTable from './components/freeResourceTable';
import { Box,  CircularProgress,  SelectChangeEvent } from '@mui/material';
import { dashboardAPIs } from '../../api/resourcesAPIs/resourceApis';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


function Home() {
    const navigate = useNavigate();

    const [response, setResponse] = useState({
        strength: {
            total: 0,
            occupied: 0,
            free: 0
        },
        stacks: {
            stacks_name: [],
            list: []
        }
    });
    const [loader, setLoader] = useState(false);

    async function fetchDashboardResponse() {
        const result : any = await dashboardAPIs();
        
        if (result){
            
            if(!result?.status){
                localStorage.getItem('token') && toast.error(result.message);
                if (result.message == "Invalid token."){
                    navigate('/login')
                }               
            }
            else{            
                setResponse(result?.data);
                setLoader(false);
            }
        }
    }


    useEffect( () => {       
        setLoader(true);
        fetchDashboardResponse();
    }, [])


  
  return (
    <Grid spacing={0} className='mainApp'>
            <ResponsiveAppBar />             

            <Box className='dash-container'></Box>

            <Box className='card-container'>
                <Grid>
                    <Grid item md={1}></Grid>
                    <Grid item md={10}>
                        <ProjectsCard response={response} loader={loader}/>
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>                    
            </Box>

            <Box className="tables-section">
                
                <Grid>
                    <Grid item md={1}></Grid>
                    <Grid item md={10}>
                    { loader ? <div  style={{display:'flex',justifyContent:'center', height:'100%', alignItems:'center'}}>
                                <CircularProgress size={24} color="inherit" /> 
                            </div> : <EnhancedTable response={response} loader={loader}/> }
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
                        
            </Box>


    </Grid>

  );
}

export default Home;
