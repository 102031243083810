import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Avatar } from '@mui/material';
import { getBoolenYesOrNO, getDateFromString, getFullName } from '../../../utils/utils.func';


const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Project',
  },
  {
    id: 'allocation',
    numeric: false,
    disablePadding: false,
    label: 'Allocation',
  },
  {
    id: 'role',
    numeric: true,
    disablePadding: false,
    label: 'Role',
  },
  {
    id: 'start-date',
    numeric: true,
    disablePadding: false,
    label: 'Start Date',
  },
  {
    id: 'end-date',
    numeric: true,
    disablePadding: false,
    label: 'End Date',
  },
  {
    id: 'billable',
    numeric: true,
    disablePadding: false,
    label: 'Billable',
  }
  
];

function EnhancedTableHead() {

  return (
    <TableHead>
      <TableRow className='table-header'>

        <TableCell className='table-header-cell'
        ></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            className='table-header-cell'
            key={headCell.id}            
            padding={headCell.disablePadding ? 'none' : 'normal'}          
          >
          {headCell.label}
        </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function ResourceProjectTable({projects}: any) {


  return (
    <Box sx={{ width: '100%', borderRadius:"30px" }}>
      <Paper sx={{ width: '100%', mb: 2 , borderRadius:"30px" }}>
        <TableContainer>        
          <Table
            sx={{ minWidth: 1000 , borderRadius:"30px"  }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead   />
            <TableBody>
              {projects?.map((row: any, index: any) => {

                return (
                  <TableRow
                    hover                   
                    sx={{ cursor: 'pointer',borderBottom: 'none !important' }}                    
                  >
                    <TableCell className='table-cell'></TableCell>
                    <TableCell
                      className='table-cell'
                      component="th"
                      scope="row"
                      padding="none"
                    >
                      <div className='resource-cell'>                        
                        {row?.projectName}
                      </div>
                    </TableCell>
                    <TableCell className='table-cell'>{row.Events.allocatedHours} hrs</TableCell>
                    <TableCell className='table-cell'>{row.Events.role}</TableCell>
                    <TableCell className='table-cell'>{getDateFromString(row.Events.startDate)}</TableCell>
                    <TableCell className='table-cell'>{getDateFromString(row.Events.endDate)}</TableCell>
                    <TableCell className='table-cell'>{getBoolenYesOrNO(row.Events.billable)} </TableCell>
                    
                  </TableRow>
                );
              })}
             
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>      
    </Box>
  );
}