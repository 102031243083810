import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f8f9fa',
        textAlign: 'center',
      }}
    >
      <h1 style={{ fontSize: '6rem', margin: '0', color: '#343a40' }}>404</h1>
      <h2 style={{ fontSize: '2rem', color: '#6c757d' }}>
        Oops! The page you're looking for doesn't exist.
      </h2>
      <button
        onClick={handleGoHome}
        style={{
          marginTop: '2rem',
          padding: '0.75rem 1.5rem',
          fontSize: '1rem',
          color: '#fff',
          backgroundColor: '#007bff',
          border: 'none',
          borderRadius: '0.25rem',
          cursor: 'pointer',
        }}
      >
        Go to Home
      </button>
    </div>
  );
};

export default NotFoundPage;
