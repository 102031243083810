import { LoginResponse } from "../../pages/login/login";

const api_url = process.env.REACT_APP_API_URL;            
const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

// Get user details

export const getLoginUserDetail = async (
    email: string,
    password: string
  ): Promise<LoginResponse> => {
    const payload = JSON.stringify({
      email: email,
      password: password,
    });
  
    const postrequestOptions = {
      method: "POST",
      headers: myHeaders,
      body: payload,
    };
  
    try {
      const response = await fetch(api_url + `auth/login`, postrequestOptions);
  
      if (!response.ok) {
        throw new Error("Failed to fetch user details");
      }
  
      const result: LoginResponse = await response.json(); // Ensure result matches LoginResponse
      return result;
    }catch (error) {
        console.error("Error fetching login user details:", error);
    
        // Return a default or empty `LoginResponse` object to match the expected type
        return {
          user: null,
          token: "",
        };
      }
  };