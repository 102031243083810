import React, { useEffect, useState } from 'react';
import '../Dashboard/dashboard.css';
import './schedule.css';
import Grid from '@mui/material/Grid';
import { Box, Button, Avatar, TextField, Typography, Tooltip, InputAdornment, Pagination } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ResponsiveAppBar from '../Dashboard/components/appbar';
import { toast } from 'react-toastify';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import BookingModal from './Components/bookingModal';
import { exportAllSchedule } from '../../api/stackAPIs/stackApis';
import { Color } from 'chart.js';
import NoRecorComponent from '../../Components/NoRecordComponent';

function Schedule() {
  const [eventLoader, setEventLoader] = useState(false);
  const [resourceLoader, setResourceLoader] = useState(false);
  const [selectedResourceID, setSelectedResourceID] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [bookingData, setBookingData] = useState({});

  const [eventData, setEventsData] = useState([]);
  const [resourceData, setResourcesData] = useState([]);
  const [rowsData, setRowsData] = useState([]);
  const [projects, setProjectsData] = useState([]);

  const [filteredEvents, setFilteredEvents] = useState([]);

  const [tooltipOpen, setTooltipOpen] = useState(false); // State for tooltip visibility
  const [tooltipContent, setTooltipContent] = useState(''); // Tooltip content
  const [tooltipTarget, setTooltipTarget] = useState(null); // Element to anchor tooltip

  // State for pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(13); // Number of items per page

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const paginatedData = resourceData.slice((page - 1) * rowsPerPage, page * rowsPerPage);


  // Map events data to FullCalendar format
  const calendarEvents = filteredEvents.map((event: any) => ({
    id: event.id.toString(),
    title: event.projects.projectName,
    // title: "RPM",
    start: event.startDate,
    end: event.endDate,
    backgroundColor: event.projects.colorCode,
    // backgroundColor: "#000000",
    extendedProps: event,
  }));

  // Function to generate unique light and dark colors based on HSL
  const generateUniqueColor = () => {
    const hue = Math.floor(Math.random() * 360); // Random hue between 0 and 360
    const lightColor = `hsl(${hue}, 70%, 95%)`;  // Light color for background (higher lightness)
    const darkColor = `hsl(${hue}, 70%, 30%)`;   // Dark color for text (lower lightness)

    return { lightColor, darkColor };
  };

  const fetchEventsData = async (selectedResource: any) => {

    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    setSelectedResourceID(selectedResource.id)
    console.log(selectedResourceID);

    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "token " + token);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    setEventLoader(false)
    const api_url = process.env.REACT_APP_API_URL;
    await fetch(api_url + `events/${selectedResource.id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setEventsData(result)
        setFilteredEvents(result)
        setEventLoader(true)
      })
      .catch((error) => {
        toast.error(error)
        setEventLoader(true)
      });
  }

  // Fetch resource data and assign fixed colors
  const fetchResources = async () => {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "token " + token);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    const api_url = process.env.REACT_APP_API_URL;
    setResourceLoader(false)
    await fetch(api_url + "resource", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // Assign colors to each resource when fetching
        const resourcesWithColors = result.map((resource: any) => {
          const { lightColor, darkColor } = generateUniqueColor();
          return { ...resource, lightColor, darkColor };
        });
        setResourcesData(resourcesWithColors);
        setRowsData(resourcesWithColors);
        if (!selectedResourceID) {
          fetchEventsData(result[0])
        }
        setResourceLoader(true)
      })
      .catch((error) => {
        toast.error(error);
        setResourceLoader(true)
      });
  };

  const fetchProjects = async () => {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "token " + token);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    const api_url = process.env.REACT_APP_API_URL;
    await fetch(api_url + "projects/active", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setProjectsData(result)
      })
      .catch((error) => {
        toast.error(error)
      });
  }

  useEffect(() => {
    fetchResources();
    fetchProjects();
  }, []);

  const handleOpenBookingModal = (event: any) => {
    setBookingData(event)
    setOpenModal(true);
  };

  const handleCloseBookingModal = (reload: boolean) => {
    setOpenModal(false);
    if (reload) {
      fetchResources();
    }
  };

  const handleScheduleExport = async () => {
    toast.info("Preparing Sheet ....");
    exportAllSchedule();
  }

  const handleSearch = (e: any) => {
    const searchVal = e.target.value.toLowerCase();
    console.log('search resource ', searchVal)
    if (searchVal === "") {
      setResourcesData(rowsData);
    } else {
      const filteredRows = rowsData.filter((row: any) =>
        row.firstName.toLowerCase().includes(searchVal) || row.lastName.toLowerCase().includes(searchVal)
      );
      console.log('filteredRows', filteredRows)
      setResourcesData(filteredRows);
    }
  };

  // Handle mouse hover to show event details
  const handleEventMouseEnter = (info: any) => {
    const { event } = info;
    setTooltipContent(`
      Project: ${event.title}
      Billable: ${event.extendedProps.billable ? 'Yes' : 'No'}
      Start: ${event.startStr}
      End: ${event.endStr}
    `);
    setTooltipOpen(true);
    setTooltipTarget(info.el); // Set the hovered element
  };

  // Handle mouse leave to hide event details
  const handleEventMouseLeave = () => {
    setTooltipOpen(false);
    setTooltipTarget(null);
  };

  const formatDate = (date: any) => {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).format(new Date(date));
  };

  function getTextColor(backgroundColor: any) {
    function getLuminance(color: any) {
      if (color[0] === '#') {
        let r = parseInt(color.substr(1, 2), 16);
        let g = parseInt(color.substr(3, 2), 16);
        let b = parseInt(color.substr(5, 2), 16);
        return (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;
      }
      return 0;
    }
    const luminance = getLuminance(backgroundColor);
    return luminance < 0.5 ? 'white' : 'black';
  }


  return (
    <Grid spacing={0} className="mainApp">
      <ResponsiveAppBar />

      <Box>
        <Grid container spacing={2}>
          <Grid item md={6} className="dashboard-header">
            <div className="titleBar">Schedule</div>
          </Grid>



          <Grid item md={3}>
            <TextField
              className='search-field'
              placeholder='Search'
              onChange={handleSearch}
              InputProps={{
                startAdornment:
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>,
              }}
            />
          </Grid>

          <Grid item md={1}>
            <Button className='add-resource' onClick={handleScheduleExport}>
              <CloudDownloadIcon /> &nbsp; Export
            </Button>
          </Grid>

          <Grid item md={2}>
            {/* <div style={{ display: 'flex', alignItems: 'center', width: '20%', gap: '10px' }}> */}
            {/* <TextField label="Search" variant="outlined" className="search" /> */}
            <Button
              onClick={handleOpenBookingModal}
              className='add-resource'
            >
              Add Booking
            </Button>
            {/* </div> */}
          </Grid>

        </Grid>
      </Box>

      <Box className="calendar-container">
        <Grid container>
          <Grid item md={12} style={{ paddingRight: '25px', paddingLeft: '25px', paddingBottom: '25px' }}>
            {resourceLoader ? (
              <div style={{ display: 'flex' }}>
                {/* Sidebar with resources */}
                <div
                  style={{
                    width: '20%',
                    paddingTop: '10px',
                    paddingRight: '10px',
                    backgroundColor: 'white',
                  }}
                >
                  {paginatedData.length ? <ul style={{ listStyle: 'none', padding: '0' }}>
                    {paginatedData.map((resource: any) => (
                      <li
                        className={selectedResourceID === resource?.id ? 'selcted-row' : 'not-selected-row'}
                        key={resource.id}
                        style={{
                          marginBottom: '15px',
                          display: 'flex',
                          alignItems: 'center',
                          padding: '6px',
                          borderRadius: '10px',
                          cursor: 'pointer',
                        }}
                        onClick={() => fetchEventsData(resource)}
                      >
                        <Avatar
                          sx={{
                            width: 40,
                            height: 40,
                            bgcolor: resource.lightColor,  // Use the fixed light color for the background
                            color: resource.darkColor,     // Use the fixed dark color for the text
                            border: `1px solid ${resource.darkColor}`,  // Use the dark color for the border
                            fontSize: '16px',
                            fontWeight: '600',
                          }}
                        >
                          {`${resource.firstName.charAt(0)}${resource.lastName.charAt(0)}`}
                        </Avatar>
                        <div style={{ marginLeft: '10px' }}>
                          <strong>{resource.firstName} {resource.lastName}</strong>
                          <br />
                          {/* <span style={{ fontSize: '12px', color: '#999' }}>{resource.jobTitleId}</span> */}
                        </div>
                      </li>
                    ))}
                  </ul> : <div style={{
                    position: 'relative',
                    top: "4%",
                    left: "10%",
                  }} ><NoRecorComponent sch={true} /></div>}
                </div>

                {/* FullCalendar component */}
                {eventLoader ? (<div style={{ width: '100%', paddingTop: '20px' }}>
                  <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    events={calendarEvents}
                    selectable
                    editable
                    height="850px"
                    weekends={false}
                    headerToolbar={{
                      left: 'prev,next today',
                      center: 'title',
                      right: 'dayGridMonth,timeGridWeek,timeGridDay',
                    }}
                    dateClick={handleOpenBookingModal}
                    // eventContent={(eventInfo) => (
                    //   <div style={{ backgroundColor: eventInfo.event.backgroundColor, padding: '4px', borderRadius: '5px', color:'black' }}>
                    //     <strong>{eventInfo.event.title}</strong>
                    //   </div>
                    // )}
                    eventContent={(eventInfo) => (
                      <Tooltip
                        title={
                          <div style={{ whiteSpace: 'pre-line' }}>
                            <Typography variant="body2" color="inherit">
                              Project: {eventInfo.event.title}
                              <br />
                              Billable: {eventInfo.event.extendedProps.billable ? 'Yes' : 'No'}
                              <br />
                              Start: {formatDate(eventInfo.event.startStr)}
                              <br />
                              End: {formatDate(eventInfo.event.endStr)}
                            </Typography>
                          </div>
                        }
                        placement="top"
                      >
                        <div style={{ backgroundColor: eventInfo.event.backgroundColor, padding: '4px', borderRadius: '5px', color: getTextColor(eventInfo.event.backgroundColor) }}>
                          <strong>{eventInfo.event.title}</strong>
                        </div>
                      </Tooltip>
                    )}
                    eventClick={(info) => {
                      handleOpenBookingModal(info.event._def.extendedProps)
                    }}
                    eventMouseEnter={handleEventMouseEnter}  // Handle event hover
                    eventMouseLeave={handleEventMouseLeave}  // Handle event leave
                  />




                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'center',
                      marginTop: '20px',
                    }}
                  >
                    <Pagination
                      count={Math.ceil(resourceData.length / rowsPerPage)}
                      page={page}
                      onChange={handleChangePage}

                      shape="rounded"
                      sx={{
                        '.Mui-selected': {
                          backgroundColor: '#5257C7 !important',
                          color: '#fff !important',
                        },
                        '.MuiPaginationItem-root': {
                          '&:hover': {
                            backgroundColor: '#5257C71A !important',
                          },
                        },
                      }}
                    />
                  </Box>



                </div>) : (
                  <p>Loading...</p>
                )}
              </div>
            ) : (
              <p>Loading...</p>
            )}
          </Grid>
        </Grid>
      </Box>

      <BookingModal open={openModal} handleClose={handleCloseBookingModal} bookingData={bookingData} resourceData={resourceData} projectsData={projects} fetchResources={fetchResources} fetchEventsData={fetchEventsData} />
    </Grid>
  );
}

export default Schedule;
