import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CircularProgress, Grid, MenuItem, Select, SelectChangeEvent, 
    Switch, 
    TextField, Typography} from '@mui/material';
import Modal from '@mui/material/Modal';    
import { Height } from '@mui/icons-material';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


export default function AddProjectAnchorDrawer({ toggleDrawer, manager_list }: any) {    
    const type_list = [
    "Monthly Retainer", "Fixed Cost"
  ]

  const [loader, setloader] = useState(false);
  const [projectData, setProjectData] = useState({
      projectName:"", 
      projectCode:"", 
      startDate:"", 
      endDate:"", 
      billable:1, 
      details:"", 
      pm_id:"", 
      tl_id:"",
      colorCode:"",
      projectType: "",
      isActive: true
  });
  const [errors, setErrors] = useState({
    projectName: false,
    projectCode: false,
    startDate: false,
    endDate: false,
    pm_id: false,
    tl_id: false,
    colorCode: false,
    type: false
  });


  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 5,
    pt: 2,
    px: 4,
    pb: 3,
  };
  
  const handleAddProject = async () => {
    if (!validateFields()) {
      toast.error("Please fill out all required fields.");
      return;
    }
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "token " + token);

    const payload = JSON.stringify({
      "projectName":projectData.projectName, 
      "projectCode":projectData.projectCode, 
      "startDate":projectData.startDate, 
      "endDate":projectData.endDate, 
      "billable":projectData.billable, 
      "details":projectData.details, 
      "pm_id":projectData.pm_id, 
      "tl_id":projectData.tl_id,
      "colorCode":projectData.colorCode,
      "projectType": projectData.projectType,
      "isActive": projectData.isActive
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: payload,
    };
    setloader(true);
    const api_url = process.env.REACT_APP_API_URL;    
    await fetch(api_url + "projects", requestOptions)
    .then((response) => response.json())
    .then((result) => {
        console.log(result, "result");
        if (result?.status === "error"){
          setloader(false);
          toast.error(result?.message)
        }
        else{
          toast.success("Project Added Successfully")        
          setloader(false)
          toggleDrawer();  // Close drawer after successful submission
        }
    })
    .catch((error) => {
        toast.error(error)
        setloader(false)
    });      
  }

  const handleInputChange = (e:any) => {
    const { name, value } = e.target;
    console.log(name, value);
    
    setProjectData({
      ...projectData,
      [name]: value,
    });

  };

  const handleInputStatus = (e: any) => {
    const value = e.target.checked;
    setProjectData({
      ...projectData,
      'isActive': value,
    });
    
  }

  const validateFields = () => {
    const newErrors = {
      projectName: projectData.projectName.trim() === '',
      projectCode: projectData.projectCode.trim() === '',
      startDate: projectData.startDate === '',
      endDate: projectData.endDate === '',
      pm_id: projectData.pm_id === '',
      tl_id: projectData.tl_id === '',
      colorCode: projectData.colorCode === '',
      type: projectData.projectType.trim() === ''
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some(error => error);
  };
  

  return (
    <Box className='add-resource-anchor'>        

        <Box className='resource-form'>
            <Box className='fieldBox'>
                <Typography className='labelText' >Project Name <span style={{color: 'red'}}>*</span></Typography>  
                <TextField className="textField" placeholder='Add name' name="projectName" onChange={handleInputChange}
                        value={projectData.projectName} error={errors.projectName}
                        helperText={errors.projectName ? "Project Name is required" : ""}></TextField>
            </Box>

            <Box className='fieldBox'>
                <Typography className='labelText'>Project Code <span style={{color: 'red'}}>*</span></Typography>  
                <TextField className="textField" placeholder='Add code' name="projectCode" onChange={handleInputChange}
                        value={projectData.projectCode} error={errors.projectCode}
                        inputProps={{ maxLength: 12 }}
                        helperText={errors.projectCode ? "Project Code is required" : ""}></TextField>
            </Box>

            <Grid container spacing={2}>
              <Grid item md={6}>
                <Box className='fieldBox'>
                  <Typography className='labelText'>Start Date <span style={{color: 'red'}}>*</span></Typography>  
                  <TextField className="textField" type='date' name="startDate" onChange={handleInputChange}
                        value={projectData.startDate} error={errors.startDate}
                        helperText={errors.startDate ? "Start Date is required" : ""}></TextField>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box className='fieldBox'>
                  <Typography className='labelText'>End Date <span style={{color: 'red'}}>*</span></Typography>  
                  <TextField className="textField" type='date' name="endDate" onChange={handleInputChange}
                        value={projectData.endDate} error={errors.endDate}
                        helperText={errors.endDate ? "End Date is required" : ""}></TextField>
                </Box>
              </Grid>
            </Grid>
                        
            <Box className='fieldBox'>
                <Typography className='labelText'>Program Manager <span style={{color: 'red'}}>*</span></Typography>  
                <Select
                    className="textField"  name="pm_id" onChange={handleInputChange}
                    value={projectData.pm_id} error={errors.pm_id}
                >
                    {manager_list.map((val: { id: string | number | readonly string[] | undefined; firstName: string; lastName: string; }) => <MenuItem value={val.id}>{val.firstName + " " + val.lastName}</MenuItem>)}
                </Select>
                {errors.pm_id && <Typography color="error">Manager is required</Typography>}
            </Box>

            <Box className='fieldBox'>
                <Typography className='labelText'>Team Lead <span style={{color: 'red'}}>*</span></Typography>  
                <Select
                    className="textField"  name="tl_id" onChange={handleInputChange}
                    value={projectData.tl_id} error={errors.tl_id}
                >
                    {manager_list.map((val: { id: string | number | readonly string[] | undefined; firstName: string; lastName: string; })  => <MenuItem value={val.id}>{val.firstName + " " + val.lastName}</MenuItem>)}
                </Select>
                {errors.tl_id && <Typography color="error">Team Lead is required</Typography>}
            </Box>

            <Box className='fieldBox'>
                <Typography className='labelText'>Project Type <span style={{color: 'red'}}>*</span></Typography>  
                <Select
                    className="textField"  name="projectType" onChange={handleInputChange}
                    value={projectData.projectType} error={errors.type}
                >
                    {type_list.map(val => <MenuItem value={val}>{val}</MenuItem>)}
                </Select>
                {errors.tl_id && <Typography color="error">Team Lead is required</Typography>}
            </Box>

            <Box display="fieldBox">
                <Typography className='labelText'>Status </Typography> 
                <Box display='flex'>
                  <Switch checked={projectData?.isActive} name="isActive" onChange={handleInputStatus}
                    sx={{
                    '& .MuiSwitch-thumb': {
                      color: projectData?.isActive ? '#676DFA' : '',
                    },
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                      backgroundColor: '#000000', // Track color when checked
                    },
                    
                  }}/>                
                  <Typography style={{marginLeft: '30px'}}>{projectData?.isActive ? 'Active' : 'Not Active'}</Typography> 
                </Box>
            </Box>

            {/* Color Picker Field */}
            <Box className='fieldBox'>
              <Typography className='labelText'>Project Color</Typography>  
              <TextField className="textField" type='color' name="colorCode" onChange={handleInputChange}
                value={projectData.colorCode}>                  
                </TextField>
            </Box>

            
            <Box className='buttonBox'>
                {/* <Button className='add-resource' onClick={handleAddProject}>
                    Add Project
                </Button> */}
                <Button className='add-resource' onClick={handleAddProject} disabled={loader}>
                  {loader ? <CircularProgress size={24} color="inherit" /> : 'Add Project'}
                </Button>
            </Box>
        </Box>

    </Box>
  );
}
