import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import ResponsiveAppBar from '../Dashboard/components/appbar';
import { Box, Button, CircularProgress, InputAdornment, TextField} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ResourcesTable from './Components/resourceTable';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { exportAllResources } from '../../api/resourcesAPIs/resourceApis';

function Resources() {
    const navigate = useNavigate();
    const [rowsData, setrowsData] = useState([{}])
    const [loader, setloader] = useState(false);
    const [resources, setResourcesData] = useState([
      {
        id: null,
        firstName:'',
        lastName: '',
        email: '',
        phoneNumber: '',
        employmentStatus: '',
        department: '',
        jobTitle: '',
        stacks: '',
        notes: '',
        managerId: '',
        timeZone: ''
      }
    ]);

    const handleaddResource = () => {
    navigate("/add-resource")
    };

    const handleSearch = (e: any) => {
        const searchVal = e.target.value.toLowerCase();
        console.log('search resource ',searchVal)
        if (searchVal === "") {
            setrowsData(resources);
        } else {
            const filteredRows = resources.filter((row: any) => 
                row.firstName.toLowerCase().includes(searchVal) || row.lastName.toLowerCase().includes(searchVal)
            );
            console.log('filteredRows',filteredRows)
            setrowsData(filteredRows);
        }
    };

    const handleExportResource = () => {
        toast.info("Preparing Sheet ...")
        exportAllResources();                
    };

    const fetchResources = async () => {
        const token = localStorage.getItem('token');
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "token " + token);
        const requestOptions = {
        method: "GET",
        headers: myHeaders,
        };
        const api_url = process.env.REACT_APP_API_URL;
        await fetch(api_url + "resource", requestOptions)
        .then((response) => response.json())
        .then((result) => {
            if(result?.message){
                toast.error(result.message)
                if (result.message == "Invalid token."){
                    navigate('/login')
                }               
            }
            else{
                setResourcesData(result)
                setrowsData(result);
                setloader(false)
            }
        })
        .catch((error) => {
            toast.error(error)
            setloader(false)
        });      
      }
    
      useEffect( () => {
          setloader(true);
          fetchResources();
      }, [])

  return (
        <Grid spacing={0}  className='mainApp'>
            <ResponsiveAppBar />  
            <Box>                
                <Grid>
                    <Grid item md={1}></Grid>
                    <Grid item md={10}>
                        <Grid container spacing={2}>
                            <Grid item md={6}>
                                <div className='titleBar'>
                                    Resources    
                                </div>
                            </Grid>
                            
                            <Grid item md={3}>
                            <TextField
                                className='search-field'
                                placeholder='Search'
                                onChange={handleSearch}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>,
                                    }}
                                />
                                
                            </Grid>
                            {/* <Grid item md={1}>
                                <Button className='add-resource'>
                                    <CloudUploadIcon /> &nbsp; Import
                                </Button>
                            </Grid> */}
                            <Grid item md={1}>
                                <Button className='add-resource' onClick={handleExportResource}>
                                    <CloudDownloadIcon /> &nbsp; Export
                                </Button>
                            </Grid>
                            <Grid item md={2}>
                                <Button className='add-resource' onClick={handleaddResource}>
                                    Add Resources
                                </Button>                                
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
                    
            </Box>   


            <Box className="table-section">
                
                <Grid>
                    <Grid item md={1}></Grid>
                    <Grid item md={10}>
                        <Grid>
                            <Grid item md={12} sm={12} xs={12}>
                                {loader && resources ? <div  style={{display:'flex',justifyContent:'center'}}>
                                <CircularProgress size={24} color="inherit" /> 
                            </div>:  <ResourcesTable resourcesArray={rowsData}/>}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
                    
            </Box>                 

    </Grid>

  );
}

export default Resources;
