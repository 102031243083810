import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';


const pages = ['Dashboard', 'Resources', 'Projects', 'Stack', 'Reports'];
const settings = ['Logout'];

function ResponsiveAppBar() {
  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [role, setRole] = React.useState(localStorage.getItem('role') || '');

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
    setAnchorElUser(null);
  };


  return (
    <>
    <AppBar position="static" className='appBar'>
      <Grid container>
        <Grid item md={12}>
          <Toolbar disableGutters>
              
              <Box sx={{display: { xs: 'none', md: 'flex' }}}>
                  <div className='app-logo-section'>
                      <div className='logo'></div>
                  </div>
              </Box>
              
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              
              <a className='btn pagesBtn linkBtn' href='/'>
                Dashboard
              </a>

              <a className='btn pagesBtn linkBtn' href='/resources'>
                Resources
              </a>

              <a className='btn pagesBtn linkBtn' href='/projects'>
                Projects
              </a>

              <a className='btn pagesBtn linkBtn' href='/schedule'>
                Schedule
              </a>

              <a className='btn pagesBtn linkBtn' href='/stacks'>
                Stacks
              </a>

              <a className='btn pagesBtn linkBtn' href='/job-title'>
                Job Title
              </a>

              <a className='btn pagesBtn linkBtn' href='/department'>
                Department
              </a>
              {role === "admin"? (
                <>
                 <a className='btn pagesBtn linkBtn' href='/work-log'>
                Utilization
              </a>

              <a className='btn pagesBtn linkBtn' href='/project-protfolio'>
                Project Protfolio
              </a></>
              ):(<></>)}
             

              {/* {pages.map((page) => (
                <Button
                  className='pagesBtn'
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page}
                </Button>
              ))} */}
            </Box>
          
          <Box sx={{display: { xs: 'flex', md: 'none' }}}>
              <div className='app-logo-section'>
                  <div className='logo'>
                    <div className='logo-img'></div>
                  </div>
              </div>
          </Box>
              

          <IconButton sx={{ p: 0 }}>
              <Avatar  sx={{ bgcolor: "#5257C7", borderColor: "#ffffff" }}></Avatar>
              <Box className="title-box">
                <span className='appbar-title'>{localStorage.getItem('name')}</span>
                <br></br>                
                <span className='appbar-designation'>{localStorage.getItem('role')==="admin"? "Admin" : "Member"}</span>
              </Box>
          </IconButton>
          
          <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                  <IconButton  onClick={handleOpenUserMenu} size='large'>
                      <ArrowDropDownCircleOutlinedIcon style={{ color: 'white'}} />
                  </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  setting === 'Logout' ? (
                    <MenuItem key={setting} onClick={handleLogout}>
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ) : (
                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  )
                ))}
              </Menu>
            </Box>


          </Toolbar>

        </Grid>
      </Grid>


    </AppBar>

    </>

  );
}
export default ResponsiveAppBar;
